import React from 'react';

export default (props: any) => (
	<div className="loading-wrapper">
		<div className="loading loading-active">
			<div className="loading-spinner-container">
				<div className="loading-spinner"></div>
			</div>
			{props.message && <p className="loading-message">{props.message}</p>}
		</div>
	</div>
);