const versions = [
	"MSXML2.XmlHttp.6.0",
	"MSXML2.XmlHttp.5.0",
	"MSXML2.XmlHttp.4.0",
	"MSXML2.XmlHttp.3.0",
	"MSXML2.XmlHttp.2.0",
	"Microsoft.XmlHttp"
];

declare var ActiveXObject: { new (s: string): any; };

type AjaxOptions = {
    timeout?: number;
}

class XHR {

    static Obj() {
        if (typeof XMLHttpRequest !== 'undefined') {
            return new XMLHttpRequest();
        }

        let xhr;
        for (let version of versions) {
            try {
                xhr = new ActiveXObject(version);
                break;
            } catch (e) {
            }
        }

        return xhr;
    }

    static send(url: string, method: string, data: any, headers: any, async: boolean = true, timeout: number = 10000) {
        if (async === undefined) {
            async = true;
        }

        return (new Promise((resolve, reject) => {
			let xhr = XHR.Obj();
			xhr.open(method, url, async);
			xhr.timeout = timeout || 10000; // 10s timeout

            if (headers) {
                for (let key in headers) {
                    if (headers.hasOwnProperty(key)) {
                        xhr.setRequestHeader(key, headers[key]);
                    }
                }
            }

            if (method == 'POST') {
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
			}

            xhr.onload = function(e: any) {
                if (this.status >= 200 && this.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                        response: xhr.response
                    });
                }
            };

            xhr.onerror = function(e: any) {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };

            xhr.ontimeout = function(e: any) {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
			};
			
            xhr.send(data);
        }));
    }
}

export default class Ajax {
	
    static get(url: string, data?: any, headers?: any, async?: boolean, timeout?: number) {
        let query = [];
        for (let key in data) {
            query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
        }
        return XHR.send(url + (query.length ? '?' + query.join('&') : ''), 'GET', null, headers, async, timeout);
    }

    static post(url: string, data?: any, headers?: any, async?: boolean, timeout?: number) {
        let query = [];
        for (let key in data) {
            query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
        }
        return XHR.send(url, 'POST', query.join('&'), headers, async, timeout);
    }
}