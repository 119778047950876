import React from 'react';

export default (props: any) => (
	<div className="awpw-footer">
		<div className={props.containerClass}>
			<footer className="row">
				<div className="col-md-12">
					<p>&copy; {new Date().getFullYear()} Vaisala Xweather. <a href="https://www.xweather.com/privacy" target="_blank">Privacy</a> + <a href="https://www.xweather.com/terms" target="_blank">Terms</a></p>
				</div>
			</footer>
		</div>
	</div>
);