import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { FormProps, IChangeEvent } from 'react-jsonschema-form';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import DateSelectorField from './DateSelectorField';
import { DATE_FORMAT } from '../../../utils';

export type FormRegistry = {
	fields: any,
	widgets: any,
	definitions: any,
	formContext: any
};

export type StateProps = {
	schema: any,
	uiSchema: any,
	idSchema: any,
	formData: any,
	errorSchema: any,
	registry: FormRegistry,
	formContext: any,
	name: string,
	restrictRange?: string,
	onChange: (state: any) => void
};

export type State = {
	from: any,
	to: any,
	fromCalendarFocused: boolean,
	toCalendarFocused: boolean
}

export default class DateRangeField extends React.Component<StateProps, State> {
	private _forceRange: string;

	constructor(props: StateProps) {
		super(props);

		this.state = {
			...props.formData,
			from: props.formData.from || moment().format(DATE_FORMAT),
			to: props.formData.to || moment().format(DATE_FORMAT),
			fromCalendarFocused: false,
			toCalendarFocused: false
		};

		const { schema, uiSchema } = this.props;
		this._forceRange = get(uiSchema, 'date.forceRange');
	}

	onDateChange = (name: string, date: any) => {
		if (date) {
			// determine if we need to force the range to be a certain period (e.g. day, month, etc)
			if (this._forceRange) {
				const m = this._forceRange.match(/^(\d+)\s*(\w+)$/);
				if (name === 'from') {
					const adjusted = date.clone().add(parseInt(m[1], 10), m[2]);
					this.setState((prevState) => ({
						...prevState,
						to: adjusted.format(DATE_FORMAT)
					}));
				} else if (name === 'to') {
					const adjusted = date.clone().subtract(parseInt(m[1], 10), m[2]);
					this.setState((prevState) => ({
						...prevState,
						from: adjusted.format(DATE_FORMAT)
					}));
				}
			}

			this.setState((prevState) => ({
				...prevState,
				[name]: date.format(DATE_FORMAT)
			}), () => this.props.onChange({
				from: this.state.from,
				to: this.state.to
			}));
		}
	};

	onFromCalendarFocusChange = ({ focused }: any) => {
		this.setState(() => ({ fromCalendarFocused: focused }));
	};

	onToCalendarFocusChange = ({ focused }: any) => {
		this.setState(() => ({ toCalendarFocused: focused }));
	};

	render() {
		const { schema, uiSchema, formData, registry: { fields } } = this.props;
		const title = uiSchema["ui:title"] ? uiSchema["ui:title"] : schema.title ? schema.title : this.props.name;
		const allowPast = get(uiSchema, 'date.allowPast') || true;
		const allowFuture = get(uiSchema, 'date.allowFuture') || false;

		const fromDate: any = moment(this.state.from, DATE_FORMAT);
		const toDate: any = moment(this.state.to, DATE_FORMAT);
		const now: any = moment();

		const outsideRange = (date: any): boolean => {
			if ((date.isAfter(now, 'day') && !allowFuture) || (date.isSameOrBefore(now, 'day') && !allowPast)) {
				return true;
			}
			return false;
		};

		// const { registry: { widgets: { DateWidget }}} = this.props;
		return (
			<div className="form-row">
				<div className="form-group col-md-3">
					<label>From</label>
					<SingleDatePicker 
						id="date-from"
						date={fromDate} 
						onDateChange={(date) => this.onDateChange('from', date)} 
						focused={this.state.fromCalendarFocused} 
						onFocusChange={this.onFromCalendarFocusChange}
						numberOfMonths={1}
						isOutsideRange={(date) => (!this.state.to || date.isAfter(toDate, 'day') || outsideRange(date))}
					/>
				</div>
				<div className="form-group col-md-3">
					<label>To</label>
					<SingleDatePicker 
						id="date-to"
						date={toDate} 
						onDateChange={(date) => this.onDateChange('to', date)} 
						focused={this.state.toCalendarFocused} 
						onFocusChange={this.onToCalendarFocusChange}
						numberOfMonths={1}
						isOutsideRange={(date) => (!this.state.from || date.isSameOrBefore(fromDate, 'day') || outsideRange(date))}
					/>
				</div>
			</div>
		);
	}
}