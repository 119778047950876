import React from 'react';
import { JSONSchema4 } from "json-schema";
import { UiSchema } from 'react-jsonschema-form';

type ErrorListProps = {
	errors: any[],
	errorSchema: any,
	schema: JSONSchema4,
	uiSchema: UiSchema,
	formContext: any
};

export default (props: ErrorListProps) => {
	const { errors } = props;
	return (
		<div className="alert alert-danger content__form__error-list" role="alert">
			<strong>Errors</strong>
			<ul>
				{errors.map((error, index) => {
					return (
						<li key={index}>{error.stack}</li>
					);
				})}
			</ul>
		</div>
	);
};