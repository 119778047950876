/// <reference path="../../typings/react-jsonschema-form.d.ts" />
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { FormProps, IChangeEvent } from 'react-jsonschema-form';
import SchemaField from "react-jsonschema-form/lib/components/fields/SchemaField";

export type FormRegistry = {
	fields: any,
	widgets: any,
	definitions: any,
	formContext: any
};

export type StateProps = {
	schema: any,
	uiSchema: any,
	idSchema: any,
	formData: any,
	errorSchema: any,
	registry: FormRegistry,
	formContext: any,
	name: string,
	description?: string,
	onChange: (state: any) => void
};

export type State = {
	collapsed: boolean
}

export default class CollapsibleField extends React.Component<StateProps, State> {

	constructor(props: StateProps) {
		super(props);

		// initial collapsed state can be defined in uiSchema for this field
		const { uiSchema: { collapse: { collapsed = true } = {} } } = props;

		this.state = {
			...props.formData,
			collapsed: collapsed
		};
	}

	componentDidMount() {
		const node = ReactDOM.findDOMNode(this);
		if (node instanceof Element) {
			const el: HTMLElement = node.querySelector('.form__collapsible-content');
			if (el) {
				el.style.height = `${el.scrollHeight}px`;
			}
		}
	}

	componentDidUpdate() {
		const node = ReactDOM.findDOMNode(this);
		if (node instanceof Element) {
			const el: HTMLElement = node.querySelector('.form__collapsible-content');
			const sizer: HTMLElement = node.querySelector('.form__collapsible-content > div');
			if (el) {
				const computedStyle = window.getComputedStyle(sizer);
				const margins = parseInt(computedStyle.marginTop, 10) + parseInt(computedStyle.marginBottom, 10); 
				el.style.height = `${sizer.scrollHeight + margins + 10}px`;
			}
		}
	}

	onChange = (e: any) => {
		const target = e.target;
		const name = target.name;
		const value = target.value;

		this.setState((prevState) => ({
			...prevState,
		}), () => this.props.onChange(this.state));
	};

	handleToggleCollapsed = () => {
	    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
    };

	render() {
		let { schema, uiSchema, formData, registry: { fields } } = this.props;
		const title = uiSchema["ui:title"] ? uiSchema["ui:title"] : schema.title ? schema.title : this.props.name;
		const description = uiSchema["ui:desc"] ? uiSchema["ui:title"] : schema.desc ? schema.desc : this.props.description;
		const toggleClass = this.state.collapsed ? '' : 'form__collapsible-toggle-expanded';
		const contentClass = this.state.collapsed ? 'form__collapsible-content-collapsed' : '';
		delete uiSchema['ui:field'];

		return (
			<div className="form__collapsible">
				<legend className={`form__collapsible-toggle ${toggleClass}`} onClick={this.handleToggleCollapsed}>
					{title} 
					<span>{this.state.collapsed ? 'expand' : 'collapse'}</span>
					<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<path d="m48.668 27.27l-35.031 35.035 6.4258 6.4258 28.605-28.602 28.609 28.602 6.4258-6.4258z" fillRule="evenodd"/>
					</svg>
				</legend>
				<div className={`form__collapsible-content ${contentClass}`}>
					<div>
						{description && <div className="form__collapsible-desc">{description}</div>}
						<div>
							<SchemaField {...this.props} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}