import React from 'react';
import useProfile from '../../hooks/useProfile';
import { AppMetadata } from '../../types';
import LoadingIndicator from '../LoadingIndicator';

export type Props = {
	selectedKey?: string,
	onSelect: (id: string, key: string) => void
};

export type AppListingProps = Props & {
	apps: AppMetadata[]
}

export type State = {};

class AppListing extends React.Component<AppListingProps, State> {

	componentDidMount() {
		// if no selected key yet, just select the first item returned 
		const apps = this.props.apps;
		if (apps?.length > 0 && !this.props.selectedKey) {
			this.props.onSelect(apps[0]?.clientId, apps[0]?.id);
		}
	}

	render() {
		const apps = [...this.props.apps];

		// add "None" option that will just use AW keys for the preview
		apps.push({
			name: 'None',
			active: true,
			namespace: '*',
			clientId: null,
			id: null
		});
		
		const items = apps.map(app => {
			return (
				<li key={app.id} 
					data-app-key={app.id} 
					className={app.id === this.props.selectedKey ? 'selected' : ''}
					onClick={() => this.props.onSelect(app.clientId, app.id)}
				>
						{app.name}
				</li>
			);
		});

		return (
			<div className="account__apps">
				<h3 className="account__title">Your Applications<span><a href="https://account.aerisweather.com" target="_blank">Account Dashboard</a></span></h3>
				{(this.props.apps?.length > 0) ? (
					<div>
						<p>Select one of your registered Xweather applications below. The provided code will be updated to use your selected application keys which you can then copy and paste into your custom projects.*</p>
						<ul className="account__apps-listing">
							{items}
						</ul>
						<p className="note-sm">* Your account may not have access to all features you've selected and the preview may contain different results when selecting your registered applications. Also, applications that have restricted their namespace may result in request errors within the preview when active. You will want to select "None" in this case to review the previewed content.</p>
					</div>
				) : (
					<p>You don't have any apps setup for your account yet. You can <a href="https://account.aerisweather.com/account/apps">setup new apps</a> from your Xweather account.</p>
				)}
			</div>
		);
	}
}

/**
 * Extends the App Listing to use the useProfile hook.
 */
const AppListingWithProfile = (props:Props) => {
	const { profile, isLoading, isErrored } = useProfile();

	if (isLoading) {
		return <LoadingIndicator message="Loading account data..." />
	}

	if (isErrored) {
		return <p>There was an error loading your account data. Please refresh the page and try again.</p>;
	}

	return <AppListing apps={profile?.apps} {...props} /> 
}

export default AppListingWithProfile;