/// <reference path="../../typings/interweave.d.ts" />
import React, { useState } from 'react';
import Interweave from 'interweave';
import Ajax from '../../Ajax';
import { AUTH_URL } from '../../../config/constants';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

export type Props = {
	onLogin: (data: any) => void
};

export type State = {
	username?: string,
	password?: string,
	error: string,
	[key: string]: any
};

const Login = ({ 
	onLogin = () => {}
}: any): React.ReactElement => {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const [error, setError] = useState();
	const { loginWithPopup } = useAuth0();
	// const onFieldChange = (e: any) => {
	// 	const name = e.target.name;
	// 	const value = e.target.value;
	// 	this.setState(() => ({ [name]: value }));
	// };

	// const onSubmit = (e: any) => {
	// 	e.preventDefault();

	// 	if (!username || !password) {
	// 		setError('Invalid username or password.');
	// 	} else {
	// 		Ajax.post(`${AUTH_URL}?_t=${new Date().getTime()}`, {
	// 			user: this.state.username,
	// 			pw: this.state.password
	// 		}).then((res: string) => {
	// 			const json = JSON.parse(res);
	// 			if (json.success) {
	// 				onLogin(json.response);
	// 			} else {
	// 				this.setState(() => ({ error: json.error }));
	// 			}
	// 		}).catch((e: any) => {
	// 			console.error('Account login failed', e);	
	// 		});
	// 	}
	// };

	const handleLogin = () => {
		loginWithPopup();
	};

	return (
		<div className="account__login">
			<h3 className="account__title">Use your Xweather account</h3>
			<p>Log in to your existing Xweather account to use your access keys in the output. If you don't have an Xweather account yet, <a href="https://signup.xweather.com/">sign up for our free Developer account</a>.</p>
			{!!error && <p className="alert alert-danger account__login-error">{<Interweave content={error} />}</p>}
			<div>
				<button className="btn btn-primary btn-wide" onClick={handleLogin} style={{ marginRight: '8px' }}>Log In</button>
				<a className="btn btn-outline btn-wide" target="_blank" href="https://signup.xweather.com/">Sign Up</a>
			</div>
		</div>
	);
};

export default Login;