import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { isFunction, cloneDeep, merge, isPlainObject } from 'lodash';
import Interweave from 'interweave';
import { StoreState } from '../types/store';
import * as actions from '../actions/wizard';
import StepContent from './StepContent';
import { WizardContext } from '../context/WizardContext';
import Preview from './Preview';

export type StateProps = {
	index: number,
	id: string,
	title: string,
	account?: any,
	description: string,
	component?: any,
	items?: any[],
	stateData?: any,
	requiresValidation?: boolean,
	requiresSubmit?: boolean,
	resetsState?: boolean,
	isValidated?: boolean,
	showsPreview?: boolean,
	previewElement?: (data: any, stepId: string, stepIndex: number) => React.Component,
	onSubmit?: Function,
	onReset?: Function
};

export type DispatchProps = {
	goNext?: () => void;
	goToStep?: (step: number | string) => void;
	resetStep: (path: string, index: number) => void;
};

type State = {
	index: number;
	id: string;
	data: any;
};

class Step extends React.Component<StateProps & DispatchProps, State> {

	constructor(props: any) {
		super(props);

		this.state = {
			index: this.props.index,
			id: this.props.id,
			data: { ...this.props.stateData }
		};
	}

	componentDidUpdate() {
		const { id, index, stateData, account } = this.props;
		if (id !== this.state.id || index !== this.state.index) {
			this.setState({ id, index, data: { ...stateData } });
		}
	}

	handleStepDataChange = (key: string, data: any, callback?: () => void) => {
		const { resetsState } = this.props;

		// merge new data with existing if it's an object in case caller does not provide full object props
		if (isPlainObject(data)) {
			data = { ...this.state.data[key], ...data };
		}

		if (resetsState && data !== this.state.data[key]) {
			this.props.resetStep(key, this.props.index);
		}

		this.setState((prevState) => ({
			...prevState,
			data: { 
				...prevState.data,
				[key]: data
			}
		}), () => {
			if (callback) {
				callback();
			}
		});
	}

	handleDone = (step?: string) => {
		if (step && this.props.goToStep) {
			this.props.goToStep(step);
		} else if (this.props.goNext) {
			this.props.goNext();
		}
	};

	handleContinueClick = () => {
		this.props.goNext();
	};

	render() {
		let { id, title, description, items, stateData } = { ...this.props };	// create shallow copy so we don't override original `title` or `description` values

		if (isFunction(title)) {
			title = title(stateData);
		}	
		if (isFunction(description)) {
			description = description(stateData);
		}

		const showsPreview = this.props.showsPreview && this.props.previewElement;
		const previewData = { 
			stepId: id, 
			...this.state.data,
			account: this.props.account
		};
		// console.log('Step', this.props.stateData, this.state);

		return (
			<WizardContext.Provider value={{
				...this.state,
				currentStep: {
					index: this.state.index,
					id: this.state.id
				},
				stateData: this.props.stateData,
				currentData: this.state.data,
				setCurrentData: (key: string, data: any) => {
					this.handleStepDataChange(key, data);
				}
			}}>
				<div className="row">
					<div className={`col-xl-${showsPreview ? '6' : '12'} step-${id}`}>
						<h2 className="content__title">{<Interweave content={title} />}</h2>
						<p className="content__desc">{<Interweave content={description} />}</p>
						<div className="content__container">
							{this.props.component ? (
								this.props.component
							) : (
								<StepContent 
									stepId={id}
									items={items} 
									stepData={{ [id]: this.state.data[id] }} 
									stateData={{ ...stateData, ...this.state.data }} 
									requiresValidation={this.props.requiresValidation} 
									requiresSubmit={this.props.requiresSubmit}
									resetsState={this.props.resetsState}
									onSubmit={this.props.onSubmit} 
									onDone={this.handleDone} 
									onReset={this.props.onReset} 
									onDataChange={this.handleStepDataChange}
								/>
							)}
						</div>
					</div>
					{showsPreview && (
						<div className="col-xl-6">
							<div className="content__container-preview">
								{isFunction(this.props.previewElement) ? this.props.previewElement(previewData, this.state.id, this.state.index) : React.cloneElement(this.props.previewElement, { data: previewData })}
							</div>
						</div>
					)}
				</div>
			</WizardContext.Provider>
		);
	}
};

const mapStateToProps = (state: StoreState, props: any): StateProps => {
	const index = state.wizard.stepIndex;
	return {
		...props,
		index: state.wizard.stepIndex,
		data: state.wizard.steps[index] ? state.wizard.steps[index].data : undefined,
		account: state.account
	};
};

const mapDispatchToProps = (dispatch: Dispatch<actions.WizardAction>): DispatchProps => {
	return {
		resetStep: (path: string, index: number) => dispatch(actions.resetStep(path, index))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);