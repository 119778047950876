import React from 'react';
import uuid from 'uuid';
import { isFunction } from 'lodash';
import { ContentItem } from '../../types';

export interface Props extends ContentItem {
	renderer: any,
	content: Array<{
		id?: string,
		className?: string,
		content: string
	}>,
	columns?: Array<{
		className?: string,
		content: Array<{
			id?: string,
			className?: string,
			content: string
		}>
	}>
};

export default class HtmlContainer extends React.Component<Props, {}> {

	render() {
		const columnClass = this.props.content ? `col-md-${Math.floor(12 / this.props.content.length)}` : '';
		const classes = !!this.props.className ? this.props.className : '';
		let columns: any = '';

		const renderContent = (items: any): any => {
			return items.map((item: any) => {
				let className = !!item.className ? item.className : '';
				if (/col-\w\w-\d{1,2}/.test(className) === false) {
					className += ` ${columnClass}`;
				}
	
				return this.props.renderer({
					...item,
					className: className.replace(/^\s+/, '')
				});
			});
		};

		if (this.props.columns) {
			columns = this.props.columns.map((column: any, index: number) => {
				return (
					<div key={`column-${index}`} className={column.className}>
						{renderContent(column.content)}
					</div>
				);
			});
		} else if (this.props.content) {
			columns = renderContent(this.props.content);
		}

		return (
			<div className={classes}>
				<div className="row">
					{columns}
				</div>
			</div>
		);
	}
}