import React from 'react';
import { isFunction } from 'lodash';

type Props = {
	steps: {
		title: string
	}[],
	stateData: any,
	completed: number[],
	selectedIndex: number,
	maxIndex: number,
	goToStepIndex: (index: number) => void
};

const StepNavigation = (props: Props) => {
	return (
		<ol className="navigation__steps">
			{
				props.steps.map((step, index) => {
					step = {...step};	// create shallow copy so we don't override original `title` value
					const isActive = index === props.selectedIndex;
					const isCompleted = props.completed.indexOf(index) !== -1 || (!isActive && index === props.maxIndex);

					let cls: string[] = [];
					if (isActive) cls.push('active');
					if (isCompleted) cls.push('completed');

					let title = step.title;
					if (isFunction(title)) {
						title = title(props.stateData);
					}

					return (
						<li 
							key={index} 
							className={cls.join(' ')}
							onClick={(e) => props.goToStepIndex(index)}
						><span>{title}</span></li>
					);
				})
			}
		</ol>
	);
};

export default StepNavigation;