import Store, { createStore, combineReducers } from 'redux';
import { StepConfig } from '../types/index';
import { StoreState, WizardState } from '../types/store';
import defaultState from './defaultState';
import wizardReducer from '../reducers/wizard';
import accountReducer from '../reducers/account';

export default (wizardState: WizardState) => {
	const initialState = {...defaultState, wizard: {...defaultState.wizard, ...wizardState, stepIndex: 0 }};
	const store = createStore<StoreState>(combineReducers({
		wizard: wizardReducer,
		account: accountReducer
	}), initialState);

	return store;
};