import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory as createHistory } from 'history';
import { get, set, unset, isFunction } from 'lodash';
import { AppProps, StepConfig } from './types/index';
import { WizardState } from './types/store';
import configureStore from './store/configureStore';
import Wizard from './components/Wizard';
import { asQueryObject, asQueryString, parseJWTToken } from '../utils';

interface Props extends AppProps {
	steps: StepConfig[];
	enableJwt: boolean;
}

const Container: React.FunctionComponent<any> = (props: Props) => {
	const history = createHistory();
	const query = history.location.search;
	const { steps, enableJwt = true } = props;

	let initialState: WizardState = {
		steps: [...props.steps],
		stepIndex: 0,
		data: {},
		completed: []
	};

	steps.forEach(step => {
		let validate = step.requiresValidation || false;
		step.items.forEach(item => {
			if (item.type === 'form') {
				validate = true;
			}
		});
		step.requiresValidation = validate;
	});

	const wizardProps = { ...props, steps, enableJwt };

	// setup initial state from query if provided
	if (query) {
		// first set data from query string params
		let data = asQueryObject(query);
		if (enableJwt && data.token) {
			// parse state from JWT token string
			if (data.token) {
				data = parseJWTToken(data.token);
			}
		} else {
			// clear out values that aren't part of any step state
			let allowed: string[] = [];
			steps.forEach((s: StepConfig) => {
				s.items.forEach((item) => {
					if (item.type === 'container') {
						item.columns.forEach((c: any) => {
							if (c.content) {
								c.content.forEach((cc: any) => {
									if (cc.key) allowed.push(cc.key);
								});
							}
						});
					} else {
						allowed.push(item.key);
					}
				});
			});
			allowed = allowed.filter((v: string) => typeof v !== 'undefined');

			Object.keys(data).forEach((k: string) => {
				if (allowed.indexOf(k) === -1) {
					unset(data, k);
				}
			});

			// now update query string with valid state params
			history.replace(`${history.location.pathname}?${asQueryString(data)}`);
		}

		initialState.data = data;
		// console.log('initialState.data', initialState.data);

		// now pre-set which steps can be marked as completed based on required data values
		let lastCompletedIndex = -1;
		steps.forEach((step, i) => {
			let stepCompleted = true;

			step.items.forEach(item => {
				const type = item.type;
				const content = item.content;

				if (type === 'form') {
					const formContent = isFunction(content) ? content(initialState.data) : content;
					const required: string[] = formContent.schema.required || [];
					required.forEach(key => {
						const isset = get(initialState.data, `${item.key}.${key}`) !== undefined;
						if (!isset) stepCompleted = false;
					});
				} else if (type === 'collection') {
					const entries = isFunction(item.content) ? item.content(initialState.data) : item.content;
					// only consider the collection to be set if the provided value actually exists in the collection
					const valid = (entries || []).map((o: any) => o.value);
					const val = get(initialState.data, item.key);
					
					if (item.multiselect === true) {
						const valStr: string = `${val}`;
						const vals = /,/.test(valStr) ? valStr.split(',') : [val];
						vals.forEach(val => {
							const index = valid.indexOf(val);
							if (index === -1) {
								vals.splice(index, 1);
							}
						});
						set(initialState.data, item.key, vals);
					} else if (!val || valid.indexOf(val) === -1) {
						unset(initialState.data, item.key);
						stepCompleted = false;
					}
				}
			});

			if (stepCompleted && i === lastCompletedIndex + 1) {
				lastCompletedIndex = i;
				initialState.completed.push(step.id);
			}
		});
	}

	const store = configureStore(initialState);
	store.subscribe(() => {
		const state = store.getState();
	});

	return (
		<Provider store={store}>
			<Wizard {...wizardProps} history={history} />
		</Provider>
	);	
};

export default Container;