import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { AERIS_WEB_API_BASE_URL, AUTH0_WEB_API_AUDIENCE } from '../../config/constants';

interface App {
	active: boolean,
	clientId: string,
	id: string,
	name: string,
	namespace: string
};

export interface ProfileSubscriptionItem  {
	priceId: string,
	product: string
}[];

export interface ProfileSubscriptions {
	cancelAt: number | null,
	cancelAtPeriodEnd: boolean,
	currentPeriodStart: string,
	currentPeriodEnd: string,
	items: ProfileSubscriptionItem[],
	status: "active" | "inactive"
};

export interface ProfileData {
	aerisClientId: string | null,
	apps: App[] | null,
	companyName: string | null,
	email: string,
	emailVerified: boolean,
	familyName: string,
	givenName: string,
	id: string,
	organization: any | null,
	permissions: string[],
	stripeCustomerId: string,
	subscriptions: ProfileSubscriptions[] | null
};

export interface ProfileHook {
	profile:ProfileData, 
	isLoading:boolean
	isErrored:boolean,
	error: any,
	refresh: () => void
};

/**
 * Fetches user profile from the Aeris Web API. This contains
 * more information than the Auth0 user, including data
 * like the user's apps and subscriptions.
 */
const useProfile = ():ProfileHook => {
	const auth0 = useAuth0();
	const [profile, setProfile] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isErrored, setIsErrored] = useState(false);
	const [error, setError] = useState();
	const [refreshIndex, setRefreshIndex] = useState(0);

	useEffect(() => {
		const fetchProfile = async () => {
			setIsLoading(true);
			setIsErrored(false);
			try {
				console.log('!!! Fetching profile', AUTH0_WEB_API_AUDIENCE, AERIS_WEB_API_BASE_URL);
				const token = await auth0.getAccessTokenSilently({
					audience:AUTH0_WEB_API_AUDIENCE
				});
				if (token) {
					const response = await fetch(
						`${AERIS_WEB_API_BASE_URL}/users/me`,
						{
							headers: {
								Authorization: `Bearer ${token}`
							}
						 }
					);
					const responseJson = await response.json();
					const profile = responseJson.response?.user;
					setProfile(profile);
				}
			} catch (err) {
				console.log(err);
				setIsErrored(true);
				setError(err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchProfile();
	}, [refreshIndex]);
	
	// call this function to refetch the profile
	const refresh = () => setRefreshIndex(refreshIndex + 1);

	return { 
		profile, 
		isLoading,
		isErrored,
		error,
		refresh
	};
};

export default useProfile;
