import Action from '../Action';
import defaultState from '../store/defaultState';
import { AccountState } from '../types/store';
import { AccountAction } from '../actions/account';
import { SET_ACCESS_KEYS, SET_ACCOUNT_APPS } from '../constants';

export default (state: AccountState = defaultState.account, action: AccountAction): AccountState => {
	switch (action.type) {
		case SET_ACCESS_KEYS:
			const { id, key } = action.payload;
			return {...state, id, key};
		case SET_ACCOUNT_APPS:
			const { apps } = action.payload;
			return {...state, apps};
		default:
			return state;
	}
};