import React from 'react';
import { omit, pick } from 'lodash';
import { addClassName } from '../../../utils';

export type Props = {
	id?: string,
	className?: string,
	content: string,
	size: {
		width: number|'auto',
		height: number|'auto'
	}
};

const Image = (props: Props) => {
	const className: string = addClassName(props.className, 'content__img');
	return (
		<div className={className}>
			<img {...props.size} src={props.content} />
		</div>
	);	
};

export default Image;