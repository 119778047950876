import React from 'react';
import { addClassName } from '../../../utils';

export type Props = {
	id?: string,
	className?: string,
	content: string|any
};

export default class Html extends React.Component<Props, {}> {

	getContent(): {__html: string} {
		const content = (): string => {
			// if item.content is a function, then we need to pass the store data to it for rendering
			if (({}.toString.call(this.props.content) === '[object Function]')) {
				return this.props.content();
			}
			return this.props.content;
		};

		return {
			__html: content()
		};
	}

	render() {
		const className: string = addClassName(this.props.className, '');
		return React.createElement('div', {
			id: this.props.id,
			className,
			dangerouslySetInnerHTML: this.getContent()
		});
	}
}