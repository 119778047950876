import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { StoreState } from '../../types/store';
import { AppMetadata, ContentItem } from '../../types';
import * as actions from '../../actions/account';
import Login from '../account/Login';
import AppListing from '../account/AppListing';
import LoadingIndicator from '../LoadingIndicator';
import Ajax from '../../Ajax';
import { addClassName } from '../../../utils';
import { AUTH_URL } from '../../../config/constants';
import { Auth0ContextInterface, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID, AUTH0_WEB_API_AUDIENCE} from '../../../config/constants'
import useProfile from '../../hooks/useProfile';

export type StateProps = {
	id?: string,
	className?: string;
	performRequest: boolean;
	account: {
		id: string,
		key: string,
		apps: AppMetadata[]
	},
	auth0: Auth0ContextInterface,
	profile: any
};

export type DispatchProps = {
	setAccessKeys?: (id: string, key: string) => void,
	setAccountApps?: (apps: AppMetadata[]) => void
};

export type State = {
	id?: string,
	loggedIn: boolean,
	loading: boolean
};

interface AccountMetadata {
	email: string;
	id: string;
	stripeCustomerId: string;
	subscriptions: Array<any>;
	apps: Array<AppMetadata>;
}

export class Account extends React.Component<StateProps & DispatchProps, State> {
	state: State = {
		id: undefined,
		loggedIn: false,
		loading: false
	};


	handleSelectApp = (id: string, key: string) => {
		this.props.setAccessKeys(id, key);
	};

	render() {
		const { isAuthenticated, isLoading} = this.props.auth0;
		const className: string = addClassName(this.props.className, 'account');
		const { key } = this.props.account;
		const loadingMessage = 'Loading account data...';

		return (
			<div className={className}>
				{(isLoading) ? <LoadingIndicator message={loadingMessage} /> : (
					(isAuthenticated) ? <AppListing selectedKey={key} onSelect={this.handleSelectApp} /> : <Login />
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: StoreState, props: any): StateProps => {
	return {
		...props,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<actions.AccountAction>): DispatchProps => {
	return {
		setAccessKeys: (id: string, key: string) => dispatch(actions.setAccessKeys(id, key)),
		setAccountApps: (apps: AppMetadata[]) => dispatch(actions.setAccountApps(apps))
	};
};

/**
 * Wraps the component in order to use the auth0 hook.
 */
const AccountWithAuth0Hook = (props:any) => {
	const auth0 = useAuth0();
	return <Account auth0={auth0} {...props} />;
};

/**
 * Wraps the component in the Auth0 Provider for authentication.
 * Audience can be set when retrieving the access token for calling
 * an API.
 */
const AccountWithAuth0Provider = (props:any) => {
	return <Auth0Provider
		domain={AUTH0_DOMAIN}
		clientId={AUTH0_CLIENT_ID}
		redirectUri={window.location.origin}
	>
		<AccountWithAuth0Hook {...props} />
	</Auth0Provider>
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWithAuth0Provider);