import jwt from 'jsonwebtoken';
import { isObject, isString, isNumber, toNumber, isPlainObject, isArray, get, set, unset, merge, cloneDeep } from 'lodash';
import Ajax from './core/Ajax';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const lpad = (value: string | number, length: number): string => {
	const parts = value.toString().split('.');
	const neg = parseFloat(parts[0]) < 0;
	let v = parts[0].replace(/^-/, '');

	if (/[^0-9\.-]/.test(value.toString())) {
		return `${value}`;
	}
	
    while (v.length < length) {
        v = `0${v}`;
    }
    return (neg ? '-' : '') + v + (parts.length === 2 ? `.${parts[1]}` : '');
};

export const query = (url: string, key: string): string => {
	key = key.replace(/[\[\]]/g, '\\$&');

	const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) return null;
	if (!results[2]) return '';
	
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const keypath = (o: any, prefix: string = '', until: string = ''): string => {
	return Object.keys(o).reduce((next: string, current: string): any => {
		const k = `${current}.${next}`;
		const v = get(o, current);
		if (isPlainObject(v) && Object.keys(v).length === 1 && (until === '' || Object.keys(v)[0] !== until)) {
			return `${current}.` + keypath(v, current);
		} else if (until === '' || next !== until) {
			return current;
		}
	}, '');
};

export const isCoord = (str: string): boolean => {
    return /^(-?[\d\.]+),\s*(-?[\d\.]+)$/.test(str);
};

export const addClassName = (str: string, className: string): string => {
	let names: string[] = (str || '').split(' ');
	names.push(className);

	return names.join(' ');
}

export const convertValues = (obj: any = {}, parentKey: string = undefined): any => {
	Object.keys(obj).forEach(key => {
		const val = obj[key];

		if (isObject(val)) {
			const keys = parentKey ? parentKey.split('.') : [];
			keys.push(key);
			convertValues(val, keys.join('.'));
		} else if (isCoord(val) === false) {
			if (isString(val)) {
				if (/^-?[\d\.]+$/.test(val) && /^0\d/.test(val) === false) {
					obj[key] = toNumber(val);
				} else if (val === 'true' || val === 'false') {
					obj[key] = val === 'true';
				} else if (/^\d{6}/.test(val) && (/color/i.test(key) || /color/i.test(parentKey))) {
					obj[key] = `${val}`;
				}
			}
		}
	});

	return obj;
};

export const asArrayString = (ar: any): string => {
	return ar.map((item: any) => {
		if (isPlainObject(item)) {
			return asObjectString(item);
		} else {
			return item;
		}
	}).join(';');
};

export const asObjectString = (obj: any): string => {
	return Object.keys(obj).map((k: string) => `${k}:${obj[k]}`).join('::');
};

export const asQueryString = (obj: any, prefix: string = undefined): string => {
	const result = Object.keys(obj).filter(k => obj[k]).map(k => {
		let v = obj[k];
		let key = k;

		if (/^\d+$/.test(k)) {
			key = prefix ? `${prefix}[${k}]` : k;
		} else {
			key = prefix ? `${prefix}.${k}` : k;
		}

		if (isArray(v)) {
			// return asQueryString(v, key);
			v = asArrayString(v);
		} else if (isPlainObject(v)) {
			return asQueryString(v, key);
		}
		
		return `${encodeURIComponent(key)}=${encodeURIComponent(v)}`;

	}).filter(v => v.length > 0);

	return result.join('&');
};

export const asQueryObject = (str: string): any => {
	let data = {};

	str.replace(/^\?/, '').split('&').forEach((item: string) => {
		let [ param, value ]: any[] = item.split('=');
		let skip = false;

		if (param && value && skip === false) {
			param = decodeURIComponent(param);
			value = decodeURIComponent(value);

			// handle array-based params
			const paramArrayMatch = param.match(/([^\[]+)\[(\d+)\](\.(.+))?$/);	// matches &param[0]=value1&param[1]=value2
			const arrayStringMatch = value.match(/^[^,:]+,\s*([^,]+,?\s*)+$/);		// matches param=value1,value2,value3
			const keyValueArrayMatch = value.match(/^(([\w\d_-]+):([^,;:]+)(,|;|::)?)/);	// matches param=item1:value1,item2:value2;prop1:value1
			// console.log(param, value, paramArrayMatch, arrayStringMatch, keyValueArrayMatch);

			if (paramArrayMatch && paramArrayMatch.length > 0) {
				const k = paramArrayMatch[1];
				const i: number = parseInt(paramArrayMatch[2], 10);

				// setup array for key if doesn't exist
				const ar: any[] = get(data, k) || [];
				if (paramArrayMatch.length === 5) {
					ar[i] = ar[i] || {};
					set(ar[i], paramArrayMatch[4], value);
				} else {
					ar.push(value);
				}

				set(data, k, ar);

			} else if (arrayStringMatch) {
				set(data, param, value.replace(/,\s*/, ','));
			} else if (keyValueArrayMatch) {
				const ar = value.split(';');
				value = ar.map((item: string) => {
					return item.split('::').reduce((prev: any, current: any) => {
						const [_k, _v] = current.split(':');
						prev[_k] = _v;
						return prev;
					}, {});
				});

				// if (value.length === 1 && isPlainObject(value[0])) {
				// 	value = value[0];
				// }

				set(data, param, value);

			} else if (value.indexOf(';') !== -1) {
				set(data, param, value.split(';'));
			} else {
				// handle dot-notation keys
				if (/\./.test(param)) {
					value = set({}, param.replace(/^[^\.]+\./, ''), value);
					param = param.replace(/\..*$/, '');
				}

				// if value for key already exists and is an object, merge current value into it instead of resetting param value
				const existing = get(data, param);
				if (existing && isPlainObject(existing) && isPlainObject(value)) {
					set(data, param, merge(existing, value));
				} else {
					set(data, param, value);
				}
			}
		}
	});

	data = convertValues(data);

	return data;
};

export const formSchemaForView = (config: any, type: string, viewKey: string, data: any = {}): {[key: string]: any} => {

	const use = (data: any, inheritedKey: string | any, target: any, targetKey: string): any => {
		let overrides = {};

		// either a key string or object is supported
		// if an object is provided, it should be in the format `{ key: '', config: {} }`
		if (isObject(inheritedKey)) {
			const { key, config } = <any>inheritedKey;
			inheritedKey = key;
			overrides = config;
		}

		let inherited = inherit(data, inheritedKey);
		if (overrides) {
			inherited = merge({}, inherited, overrides);
		}

		if (inherited.schema.type && isString(inherited.schema.type)) {
			set(target.schema, targetKey, inherited.schema);
		} else {
			set(target.schema, targetKey, {
				type: 'object',
				title: inherited.title || '',
				required: inherited.required || [],
				properties: inherited.schema
			});
		}

		set(target.ui, targetKey, inherited.ui);

		// inherited conditionals must be updated so their property keys are the appropriate keypath from the root of the 
		// parent schema, so iterate through the conditionals and update the keys accordingly
		if (Object.keys(inherited.conditionals).length > 0) {
			let conditionals: any = {};

			Object.keys(inherited.conditionals).forEach(key => {
				const values = inherited.conditionals[key];
				const pkey = `${targetKey}.${key}`;
				conditionals[pkey] = {};

				Object.keys(values).forEach(vkey => {
					vkey = `${vkey}`;
					const val = values[vkey];
					conditionals[pkey][vkey] = {};

					Object.keys(val).forEach(vpkey => {
						conditionals[pkey][vkey][`${targetKey}.properties.${vpkey}`] = val[vpkey]; 
					});
				});
			});

			merge(target.conditionals, conditionals);
		}
	};

	/**
	 * Convenience method that recursively merges in shared or inherited config options for a single view config
	 */
	const inherit = (data: any, key: string): any => {
		const options = get(data, `${key}.options`) || get(data, key);
		// console.log('getting config for '+key, get(config, `${key}.title`));
		
		let result: any = {
			title: get(data, `${key}.title`) || '',
			required: [],
			schema: {},
			ui: {},
			conditionals: {}
		};

		if (!options) {
			return result;
		}

		// add schema properties from parent config targets if specified
		if (options.use) {
			Object.keys(options.use).forEach((k: string|any) => {
				use(data, options.use[k], result, k);
			});
		}

		// merge in this key's schema
		if (options.schema) {
			if (!options.schema.properties) {
				merge(result.schema, options.schema);
			} else {
				merge(result.schema, options.schema.properties);
			}
		}

		// merge in shared properties as needed
		// console.log(config);
		Object.keys(result.schema || {}).forEach(key => {
			const prop = result.schema[key];
			if (prop.use) {				
				use(data, prop.use, result, key);
			}
		});

		// merge in inherited uiSchema config
		if (options.ui) {
			merge(result.ui, {...options.ui});
		}

		if (options.required) {
			result.required = [...options.required];
		}

		if (options.conditionals) {
			merge(result.conditionals, {...options.conditionals});
		}

		// pull out inherited properties as needed
		if (options.omit) {
			options.omit.forEach((k: string) => {
				const _k = k.split('.').join('.properties.');
				unset(result.schema, _k);
			});
		}

		return result;
	};

	const result = inherit(config, `${type}.${viewKey}`);

	return {
		schema: {
			type: 'object',
			required: result.required,
			properties: result.schema
		},
		uiSchema: result.ui,
		conditionals: result.conditionals
	};
};

export const toObjectStr = (obj: any, indent: number = 0): string => {
	const space = '    ';

	let formatted = JSON.stringify(obj, null, 4).replace(/\"([^(\")"]+)\":/g, "$1:");
	formatted = formatted.replace(/\},\s+\{/g, '},{');
	formatted = formatted.replace(/\[[\s\n]+\{/g, '[{');
	formatted = formatted.replace(/\}[\s\n]+\]/g, '}]');

	if (indent > 0) {
		const prefix = Array(indent).fill(space).join('');
		formatted = formatted.replace(/\n/g, `\n${prefix}`);
	}

	return formatted;
};

export const createJWTToken = (payload: any): string => {
	const secret = 'A01hFxlZmVfidmROyboW';
	const result = cloneDeep(payload);

    return jwt.sign(result, secret, {
        algorithm: 'HS256'
    });
};

export const parseJWTToken = (token: string): any => {
	const base64Url = token.split('.')[1];
	const decoded = Buffer.from(base64Url, 'base64').toString();
	const json = JSON.parse(decoded);

	return json;
};

export const setDocumentMeta = (name: string, attribute: string, value: string) => {
	const el = document.querySelector(`meta[name="${name}"]`);
	if (el) {
		el.setAttribute(attribute, value);
	}
};

export const setDocumentLink = (name: string, attribute: string, value: string) => {
	const el = document.querySelector(`link[rel="${name}"]`);
	if (el) {
		el.setAttribute(attribute, value);
	}
};

export async function getLoggedIn() {
	return Ajax.get(`https://www.aerisweather.com/data/account/user.js?_t=${new Date().getTime()}`).then((res: string) => {
		const json = JSON.parse(res);
		if (json) {
			return json.response;
		}
		return undefined;
	}).catch((e: any) => {
		console.error('Account check failed', e);	
	});
}