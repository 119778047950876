import React from 'react';
import Interweave from 'interweave';
import { isArray, isString, isFunction } from 'lodash';
import { ContentItem } from '../../types';
import { addClassName } from '../../../utils';
import { rightArrow } from '../../../icons';

export interface Props extends ContentItem {
	content: {
		title: string,
		value: string|number,
		image?: string,
		link?: string,
		description?: string,
		reset?: boolean,
		renderer?: (data: any) => any
	}[],
	cellStyle?: { [key: string]: string },
	submitOnSelect?: boolean,
	multiselect?: boolean,
	responder?: any,
	handleSelect: (data: any) => void;
	handleChange: (data: any) => void;
	handleSubmit: (data: any, advance?: boolean) => void
};

export type State = {
	selectedValues: (string | number)[]
};

export default class Collection extends React.Component<Props, State> {
	state: State = {
		selectedValues: []
	};

	private enableMultiselect = false;

	componentDidMount() {
		this.enableMultiselect = this.props.multiselect;

		const { data } = this.props;
		if (data) {
			let selected = data;
			if (isString(selected)) {
				selected = selected.split(',');
			}

			this.setState(() => ({ selectedValues: selected }));

			// determine if the initial value contains a reset value and adjust multiselect 
			const { content: items } = this.props;
			const values = items.map(item => item.value);
			const reset = items.filter(item => item.reset).map(item => item.value).filter(val => selected.indexOf(val) !== -1);

			if (reset.length > 0) {
				this.enableMultiselect = false;
			}
		}
	}

	handleSelection = (value: string|number, reset: boolean = false) => {
		let selected: (string | number)[] = reset ? [] : [...this.state.selectedValues];

		// determine if selected item contains a link, and redirect if so
		const values = this.props.content.map(item => item.value);
		const selectedIndex = values.indexOf(value);
		if (selectedIndex >= 0) {
			const item = this.props.content[selectedIndex];
			if (item.link) {
				window.location.href = item.link;
				return;
			}
		}

		// determine if we need to adjust multiselect or start with empty array
		if (reset) {
			this.enableMultiselect = false;
		} else if (!reset && this.props.multiselect !== this.enableMultiselect) {
			selected = [];
			this.enableMultiselect = this.props.multiselect;
		}

		if (this.enableMultiselect) {
			const index = selected.indexOf(value);
			if (index === -1) {
				selected.push(value);
			} else {
				selected.splice(index, 1);
			}
		} else {
			selected = [value];
		}

		this.setState(() => ({ selectedValues: selected }), () => {
			const shouldSubmit = this.props.submitOnSelect !== false;
			let selectedValues: any = this.state.selectedValues;
			// if selecting on single selection, pass the single value instead of the array
			if (shouldSubmit) {
				selectedValues = selectedValues[0] || '';
			}

			if (this.props.handleChange) {
				this.props.handleChange(selectedValues);
			}
			if (shouldSubmit) {
				this.handleSubmit();
			}
		});
	};

	handleSubmit = () => {
		this.props.handleSubmit(this.state.selectedValues.join(','), true);
	};
	
	render() {
		const { content, title, description } = this.props;
		const className: string = addClassName(this.props.className, 'content__collection');
		const values = this.state.selectedValues;
		const items: any[] = isFunction(content) ? content(this.props.stateData) : content;

		return (
			<>
				{(title || description) && (
					<div className="content__section-divider">
						{title && <div className="content__section-title">{title}</div>}
						{description && <div className="content__section-desc">{description}</div>}
					</div>
				)}
				<div id={this.props.id} className={className}>
					<div className="collection__items">
						{(items || []).map(({ title, value, image, description, reset, renderer }) => {
							const selected: boolean = values.indexOf(value) !== -1;
							const style: any = this.props.cellStyle || {};

							let cellContent = (
								<>
									<p className="collection__item-title">{title}</p>
									{image && <div className="collection__item-thumb"><img src={image} /></div>}
									{description && <p className="collection__item-desc">{<Interweave content={description} />}</p>}
								</>
							);

							if (renderer) {
								cellContent = renderer(this.state);
								if (isString(cellContent)) {
									cellContent = (
										<div dangerouslySetInnerHTML={{__html: cellContent}}></div>
									);
								}
							}

							return (
								<div key={value} className={`collection__item ${selected ? 'selected' : ''}`} style={style} data-reset={reset} onClick={(e: any) => {
									const reset = e.currentTarget.getAttribute('data-reset') === 'true';
									// don't select collection item if clicked target was a link
									if (e.target.nodeName.toLowerCase() !== 'a') {
										this.handleSelection(value, reset);
									}
								}}>
									{cellContent}
								</div>
							)
						})}
					</div>
				</div>
			</>
		);
	}
};