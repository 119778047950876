import React from 'react';
import moment from 'moment';
import { get } from 'lodash';
import { FormProps, IChangeEvent } from 'react-jsonschema-form';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import { DATE_FORMAT } from '../../../utils';

export type FormRegistry = {
	fields: any,
	widgets: any,
	definitions: any,
	formContext: any
};

export type StateProps = {
	schema: any,
	uiSchema: any,
	idSchema: any,
	formData: any,
	errorSchema: any,
	registry: FormRegistry,
	formContext: any,
	name: string,
	onChange: (state: any) => void
};

export type State = {
	date: any
	calendarFocused: boolean
}

export default class DateSelectorField extends React.Component<StateProps, State> {

	constructor(props: StateProps) {
		super(props);

		this.state = {
			...props.formData,
			date: props.formData.date || moment().format(DATE_FORMAT),
			calendarFocused: false
		};
	}

	onDateChange = (date: any) => {
		if (date) {
			this.setState((prevState) => ({
				...prevState,
				date: date.format(DATE_FORMAT)
			}), () => this.props.onChange({
				date: this.state.date
			}));
		}
	};

	onCalendarFocusChange = ({ focused }: any) => {
		this.setState(() => ({ calendarFocused: focused }));
	};

	render() {
		const { schema, uiSchema, formData, registry: { fields } } = this.props;
		const title = uiSchema["ui:title"] ? uiSchema["ui:title"] : schema.title ? schema.title : this.props.name;
		const allowPast = get(uiSchema, 'date.allowPast') || true;
		const allowFuture = get(uiSchema, 'date.allowFuture') || false;
        const date: any = moment(this.state.date, DATE_FORMAT);
		const now: any = moment();

		const outsideRange = (date: any): boolean => {
			if ((date.isAfter(now, 'day') && !allowFuture) || (date.isSameOrBefore(now, 'day') && !allowPast)) {
				return true;
			}
			return false;
		};
		
		return (
			<div className="form-row">
				<div className="form-group col-md-3">
					<label>{title}</label>
					<SingleDatePicker 
						id="date-single"
						date={date} 
						onDateChange={this.onDateChange} 
						focused={this.state.calendarFocused} 
						onFocusChange={this.onCalendarFocusChange}
						numberOfMonths={1}
						isOutsideRange={(date) => (outsideRange(date))}
					/>
				</div>
			</div>
		);
	}
}