import React from 'react';

interface Props {
    classNames?: string;
    scrollTarget?: any;
    sides?: any;
    onChange?: (active: boolean) => void;
}

interface State {
    active: boolean;
    height: number;
    width: number;
    stuckBottom: boolean;
    stuckLeft: boolean;
    stuckRight: boolean;
    stuckTop: boolean;
}

const getBounds = (target: any): any => {
    if (!target) target = window;
    return target.getBoundingClientRect ? target.getBoundingClientRect() : { 
        // target is the window
        height: target.innerHeight,
        width: target.innerWidth,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        x: target.scrollX,
        y: target.scrollY,
    };
};

class Sticky extends React.Component<Props, State> {
    static baseClass = 'sticky'

    state = {
        active: false,
        height: 0,
        width: 0,
        stuckBottom: false,
        stuckLeft: false,
        stuckRight: false,
        stuckTop: false,
    };

    frameId = 0;
    stickyDiv = React.createRef<HTMLDivElement>();
    resizeObserver: ResizeObserver = undefined;

    componentDidMount() {
        this.resizeObserver = new ResizeObserver((entries: any[]) => {
            entries.forEach((entry) => {
                if (entry.target === this.stickyDiv.current) {
                    // disable stickiness if target height is greather than scroll container height
                    const sb = getBounds(this.props.scrollTarget);
                    const tb = this.stickyDiv.current.getBoundingClientRect();
                    if (tb.height > sb.height) {
                        this.removeEvents();
                    } else {
                        this.addEvents();
                    }
                }
            })
        });

        this.addEvents();
        this.handleScroll();
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.scrollTarget !== this.props.scrollTarget) {
            this.removeEvents();
            this.addEvents();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.removeEvents();
    }

    handleScroll = () => {
        const { sides } = this.props;
        const stickyDiv: any = this.stickyDiv.current || null;
        const scrollTarget = this.props.scrollTarget || window;

        this.frameId = 0;

        if (!stickyDiv) {
            return;
        }

        const scrollRect = getBounds(scrollTarget);
        let stickyRect = stickyDiv.getBoundingClientRect();
        let isActive = false;

        if (!this.state.height || !this.state.width) {
            this.setState({
                height: stickyRect.height,
                width: stickyRect.width,
            });
        }

        stickyRect = { // Apparently you can't spread the results of a bounding client rectangle
            height: this.state.height || stickyRect.height,
            width: this.state.width || stickyRect.width,
            x: stickyRect.x,
            y: stickyRect.y,
        };

        if (typeof sides.bottom === 'number') {
            const stuckBottom = stickyRect.y + stickyRect.height > (scrollRect.height + scrollRect.top) - sides.bottom;
            this.setState({ stuckBottom });
            if (!isActive) {
                isActive = stuckBottom;
            }
        }

        if (typeof sides.top === 'number') {
            const stuckTop = stickyRect.y < scrollRect.top + sides.top;
            this.setState({ stuckTop });
            if (!isActive) {
                isActive = stuckTop;
            }
        }

        if (typeof sides.left === 'number') {
            const stuckLeft = stickyRect.x < scrollRect.left + sides.left;
            this.setState({ stuckLeft });
            if (!isActive) {
                isActive = stuckLeft;
            }
        }

        if (typeof sides.right === 'number') {
            const stuckRight = stickyRect.x + stickyRect.width > (scrollRect.width + scrollRect.left) - sides.right;
            this.setState({ stuckRight });
            if (!isActive) {
                isActive = stuckRight;
            }
        }

        if (isActive !== this.state.active) {
            this.setState({ active: isActive }, () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.active);
                }
            });
        }
    }

    debouncedScroll = () => {
        if (!this.frameId) {
            const frameId = requestAnimationFrame(this.handleScroll);
            this.frameId = frameId;
        }
    }

    handleResize = () => {
        const w = window.innerWidth;
        if (w < 1200) {
            this.removeEvents();
        } else if (!this.frameId) {
            this.addEvents();
        }

        const stickyDiv: any = this.stickyDiv.current || null;
        const stickyRect = stickyDiv.getBoundingClientRect();
        this.setState({
            height: stickyRect.height,
            width: stickyRect.width,
        });
    }

    addEvents() {
        const w = window.innerWidth;
        if (w < 1200) return;
        
        const scrollTarget = this.props.scrollTarget || window;
        if (scrollTarget && this.stickyDiv.current) {
            scrollTarget.addEventListener('scroll', this.debouncedScroll);
            this.resizeObserver.observe(this.stickyDiv.current);
        }
    }

    removeEvents() {
        const scrollTarget = this.props.scrollTarget || window;
        if (scrollTarget) {
            scrollTarget.removeEventListener('scroll', this.debouncedScroll);
        }
        if (this.frameId) {
            cancelAnimationFrame(this.frameId);
        }
    }

    render() {
        const { children, sides } = this.props;
        const { height, width, stuckBottom, stuckLeft, stuckRight, stuckTop } = this.state;
        const stickyModifiers: string[] = [];

        let style: any;

        if (stuckBottom) {
            stickyModifiers.push('stuck-bottom');
            style = {
                bottom: `${sides.bottom || 0}px`
            };
        }
        if (stuckLeft) {
            stickyModifiers.push('stuck-left');
            style = {
                left: `${sides.left || 0}px`
            };
        }
        if (stuckRight) {
            stickyModifiers.push('stuck-right');
            style = {
                right: `${sides.right || 0}px`
            };
        }
        if (stuckTop) {
            stickyModifiers.push('stuck-top');
            style = {
                top: `${sides.top || 0}px`
            };
        }

        const isActive = stickyModifiers.length > 0;

        if (style) {
            style = { 
                ...style,
                height: isActive ? `${height}px` : 'default',
                width: isActive ? `${width}px` : 'default'
            };
        } else {
            style = {};
        }

        const childrenWithStuckProps = React.Children.map(children, (child: any) => {
            const childModifiers = (child.props && child.props.modifiers) || [];
            return React.cloneElement(child, { style, modifiers: [...childModifiers, ...stickyModifiers] });
        });

        return (
            <>
                <div
                    className={`${Sticky.baseClass}${isActive ? ` ${Sticky.baseClass}-active` : ''} ${this.props.classNames || ''}`}
                    ref={this.stickyDiv}
                >
                    {childrenWithStuckProps}
                </div>
                {isActive && (
                    <div style={{ height: `${this.state.height}px` }}></div>
                )}
            </>
        );
    }
}

export default Sticky;

