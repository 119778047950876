import React from 'react';
import { ArrayFieldTemplateProps } from 'react-jsonschema-form';

export default (props: ArrayFieldTemplateProps) => {
	return (
		<div className={props.className}>
			<label>{props.title}</label>
			{props.items && props.items.map(element => (
				<div key={element.index} className="field-array-item">
					<div className="field-array-fields">{element.children}</div>
					<div className="field-array-controls">
						<button className="btn btn-danger btn-delete" onClick={element.onDropIndexClick(element.index)}>
							<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
								<path d="m14.062 0.78125l-13.281 13.281 35.938 35.938-35.938 35.938 13.281 13.281 35.938-35.938 35.938 35.938 13.281-13.281-35.938-35.938 35.938-35.938-13.281-13.281-35.938 35.938z"/>
							</svg>
						</button>
					</div>
				</div>
			))}
			{props.canAdd && (
				<div>
					<p className="col-xs-3 col-xs-offset-9 array-item-add">
						<button className="btn btn-outline-secondary btn-add" onClick={props.onAddClick} type="button">
							<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
								<path d="m44.895 6c-1.1016 0-2 0.89453-2 2v34.895h-34.895c-1.1055 0-2 0.89453-2 2v10.199c0 1.1055 0.89453 2 2 2h34.895v34.906c0 1.1016 0.89844 2 2 2h10.199c1.1055 0 2-0.89844 2-2v-34.906h34.906c1.1055 0 2-0.89453 2-2v-10.199c0-1.1055-0.89453-2-2-2h-34.906v-34.895c0-1.1055-0.89453-2-2-2z" fillRule="evenodd"/>
							</svg>
							Add
						</button>
					</p>
				</div>
			)}
		</div>
	);
};