import apiIcon from '../img/product-api.png';
import mapIcon from '../img/product-maps.png';
import mapAppIcon from '../img/product-mapapp.png';
import widgetIcon from '../img/product-widgets.png';
import wxBloxIcon from '../img/product-widgets.png';

export const PRODUCTS: any[] = [{
    id: 'api',
    title: 'API Wizard',
    image: apiIcon,
    canonical: 'https://developer.xweather.com/product/api/',
    description: 'An advanced weather API to power all of your business needs, from the basic to the most complex weather influenced solutions.',
    metaDescription: "Quickly get started with our weather data services by using our weather data API wizard to build queries and return weather data with ease.",
    links: [{
        href: 'ROOTPATH/',
        title: 'All Wizards'
    },{
        href: '//www.xweather.com/docs/weather-api',
        title: 'Documentation'
    },{
        href: '//www.xweather.com/support',
        title: 'Support'
    }],
    loader: (): Promise<any> => {
        return import(/* webpackChunkName: "aeris-wizard.api" */ '../products/api/Wizard');
    }
},{
    id: 'maps',
    title: 'Map Wizard',
    image: mapIcon,
    canonical: 'https://developer.xweather.com/product/maps/',
    description: 'A powerful and flexible mapping platform, with a variety of weather layers, for integration with your custom application.',
    metaDescription: "Quickly get started with our weather data services by using our weather mapping platform wizard to create informative weather imagery.",
    links: [{
        href: 'ROOTPATH/',
        title: 'All Wizards'
    },{
        href: '//www.xweather.com/docs/maps',
        title: 'Documentation'
    },{
        href: '//www.xweather.com/support',
        title: 'Support'
    }],
    loader: (): Promise<any> => {
        return import(/* webpackChunkName: "aeris-wizard.maps" */ '../products/maps/Wizard');
    }
},{
    id: 'mapapp',
    title: 'Interactive Map App Wizard',
    image: mapAppIcon,
    canonical: 'https://developer.xweather.com/product/mapapp/',
    description: 'The <a href="https://www.aerisweather.com/support/docs/toolkits/aeris-js-sdk/" target="_blank">JavaScript SDK</a> includes a fully-functional, feature rich interactive map application, which can serve as your own weather dashboard or be intergrated into your custom weather applications.',
    metaDescription: 'Quickly get started with our Interactive Map App by using our Interactive Map App Wizard.',
    links: [{
        href: 'ROOTPATH/',
        title: 'All Wizards'
    },{
        href: '//www.xweather.com/docs/javascript-sdk/interactive-map-app',
        title: 'Documentation'
    },{
        href: '//www.xweather.com/support',
        title: 'Support'
    }],
    loader: (): Promise<any> => {
        return import(/* webpackChunkName: "aeris-wizard.mapapp" */ '../products/mapapp/Wizard');
    }
},
{
    id: 'widgets',
    title: 'Widgets Wizard',
    image: widgetIcon,
    canonical: 'https://developer.xweather.com/product/widgets/',
    description: 'Our free weather widgets allow developers and hobbyists alike to select the view, colors/theme, units of measurement, and a singular location to display weather observations from on your site.',
    metaDescription: 'Our free weather widgets allow developers and hobbyists alike to select the view, colors/theme, units of measurement, and a singular location to display weather observations from on your site.',
    requiresAccount: true,
    links: [{
        href: 'ROOTPATH/',
        title: 'All Wizards'
    },{
        href: '//www.xweather.com/docs/weatherblox',
        title: 'Documentation'
    },{
        href: '//www.xweather.com/support',
        title: 'Support'
    }],
    loader: (): Promise<any> => {
        return import(/* webpackChunkName: "aeris-wizard.widgets" */ '../products/widgets/Wizard');
    }
}];

export const getProduct = (id: string): any => {
    return PRODUCTS.find(({ id: pid }: any) => pid === id);
};