import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { History } from 'history';
import { get, isFunction, set, uniq } from 'lodash';
import { AppProps, StepConfig } from '../types/index';
import { StoreState } from '../types/store';
import * as actions from '../actions/wizard';
import Header from './Header';
import Footer from './Footer';
import StepNavigation from './StepNavigation';
import Step from './Step';
import { asQueryString, createJWTToken } from '../../utils';
import Sticky from './ui/Sticky';

export interface StateProps extends AppProps {
	history: any,
	steps: StepConfig[],
	activeStepIndex: number,
	completed: string[],
	data: { [key: string]: any },
	showNavigation?: boolean,
	showSteps?: boolean,
	enableJwt: boolean,
	previewElement?: (data: any) => React.Component
};

export type DispatchProps = {
	setStepIndex: (index: number) => void,
	completeStep: (path: string, index: number) => void,
	saveFieldData: (key: string, data: any) => void,
	onStepChange?: () => {}
};

export type State = {
	stepIndex: number;
	maxStepIndex: number;
	isNavSticky: boolean;
};

export class Wizard extends React.Component<StateProps & DispatchProps, State> {
	history: History;
	unlisten: () => void;
	skip: string[];
	navElement: any;

	constructor(props: any, state: any) {
		super(props);

		this.state = {
			stepIndex: 0,
			maxStepIndex: 0,
			isNavSticky: false
		};
		this.history = this.props.history;
		this.skip = [];
	}

	UNSAFE_componentWillMount() {
		// validate each step has a unique id, otherwise throw an exception
		const unique = this.props.steps.filter((s1: StepConfig, index: number, arr: StepConfig[]) => arr.findIndex((s2) => s2.id === s1.id) === index);
		if (unique.length !== this.props.steps.length) {
			throw new Error('Each step must have a unique value for `id` in the configuration.');
		}

		this.unlisten = this.history.listen(({ pathname }) => {
			this.goTo(pathname);
		});

		let pathname = this.history.location.pathname;
		let index = Math.max(this.indexForStepId(pathname.replace(this.basename, '')), 0);

		// check if we can advance to the requested step based on the validation and data field requirements 
		// from all steps prior to the requested step path
		const allowed: number[] = [...this.completedStepIndexes, this.currentStepIndex];
		if (allowed.indexOf(index) === -1) {
			index = this.advanceableIndexForPath(pathname);
			this.replace(index);
			return;
		}

		this.goTo(pathname);
	}

	UNSAFE_componentWillReceiveProps(nextProps: StateProps & DispatchProps) {
		this.setState(() => ({ stepIndex: nextProps.activeStepIndex }));

		// setup skip steps if we're initializing with data
		const { data } = nextProps;
		if (data) {
			let skip = this.skip;
			nextProps.steps.forEach((step, index) => {
				if (data[step.id] && isFunction(step.onSubmit)) {
					const { goto } = step.onSubmit(data) || {};
					if (goto) {
						let paths = this.pathsFromTo(step.id, goto);
						skip = skip.concat(paths);
					}
				}
			});
			this.skip = uniq(skip);
		}

		if (this.props.appendDataQueryString) {
			const index = nextProps.activeStepIndex;
			const query = (nextProps.enableJwt) ? `token=${createJWTToken(nextProps.data)}` : asQueryString(nextProps.data);
			if (query && query.length > 0) {
				this.history.replace(`${this.basename}${this.stepIdForIndex(index)}?${query}`);
			}
		}
	}

	componentWillUnmount() {
		this.unlisten();
	}

	componentDidUpdate() {
		let max = 0;
		const completedIndexes = [...this.completedStepIndexes, this.currentStepIndex];
		completedIndexes.forEach(index => (max = Math.max(max, index)));

		if (max !== this.state.maxStepIndex) {
			this.setState((prevState) => ({ 
				maxStepIndex: max
			}));
		}
	}

	get basename(): string { 
		const basepath = this.props.basepath || '/';
		return `${basepath}`;
	}

	get ids(): string[] {
		return this.props.steps.map(s => s.id);
	}

	get currentStepIndex(): number {
		return this.state.stepIndex;
	}

	get previousStepIndex(): number {
		return Math.max(this.currentStepIndex - 1, 0);
	}

	get nextStepIndex(): number {
		return Math.min(this.currentStepIndex + 1, this.visibleSteps.length - 1);
	}

	get lastCompletedStepIndex(): number {
		return Math.max.apply(Math, this.completedStepIndexes);
	}

	get totalSteps(): number {
		return this.visibleSteps.length;
	};

	get visibleSteps(): StepConfig[] {
		return this.props.steps.filter(step => (this.skip.indexOf(step.id) === -1));
	}

	get completedStepIndexes(): number[] {
		return this.props.completed.map(path => this.indexForStepId(path));
	};

	push = (index = this.nextStepIndex) => this.history.push(`${this.basename}${this.stepIdForIndex(index)}`);
	pop = (index = this.previousStepIndex) => this.history.push(`${this.basename}${this.stepIdForIndex(index)}`);
	replace = (index = this.nextStepIndex) => this.history.replace(`${this.basename}${this.stepIdForIndex(index)}`);

	goTo = (pathname: string, props = this.props) => {
		let index = this.indexForStepId(pathname.replace(this.basename, ''));
		// if no step found for path, just redirect to first step
		if (index < 0) {
			this.replace(0);
			return;
		}

		if (index !== this.currentStepIndex) {
			this.setState(() => ({ stepIndex: index }));
			this.props.setStepIndex(index);

			if (this.props.onStepChange) {
				this.props.onStepChange();
			}
		}
	};

	goNext = () => {
		if (this.currentStepIndex < this.totalSteps) {
			const path = this.stepIdForIndex(this.currentStepIndex);
			this.props.completeStep(path, this.currentStepIndex);
			if (this.nextStepIndex > this.currentStepIndex) {
				this.push();
			}
		}
	};

	goPrevious = () => {
		if (this.currentStepIndex > 0) {
			this.pop();
		}
	};

	goToStepIndex = (index: number) => {
		const { completed } = this.props;

		if (index >= 0 && index < this.totalSteps && index != this.currentStepIndex) {
			if (index > this.currentStepIndex) {
				// check to make sure none of the steps between the current stepIndex and the requested index require validation
				// if any of them do, check if they have already been completed first and if not, don't allow skipping to the
				// step at the requested index
				let canAdvance = true;
				let checkIndex = 0;

				while (checkIndex < index) {
					if (this.completedStepIndexes.indexOf(checkIndex) === -1) {
						canAdvance = false;
						break;
					}
					checkIndex += 1;
				}

				if (!canAdvance) {
					// console.log(`Cannot advance to step index ${index}, intermediate steps not completed!`);
					return;
				}
			}

			this.push(index);
		}
	};

	skipTo = (pathname: string) => {
		let index = this.indexForStepId(pathname.replace(this.basename, ''), true);

		// first flag skipped steps so they don't appear in the navigation
		let skip: string[] = [];
		this.props.steps.forEach((step, i) => {
			if (i > this.currentStepIndex && i < index) {
				skip.push(step.id);
				index -= 1;
			}
		});
		this.skip = skip;

		// now go to the desired step
		const path = this.stepIdForIndex(this.currentStepIndex);
		this.props.completeStep(path, this.currentStepIndex);
		this.push(index);
	};

	pathsFromTo = (from: string, to: string) => {
		const fromIndex = this.indexForStepId(from);
		const toIndex = this.indexForStepId(to);

		let paths: string[] = [];
		this.props.steps.forEach((step, i) => {
			if (i > fromIndex && i < toIndex) {
				paths.push(step.id);
			}
		});
		
		return paths;
	}

	advanceableIndexForPath = (pathname: string, props = this.props): number => {
		let index = Math.max(this.indexForStepId(pathname.replace(this.basename, '')), 0);
		let lastCompletedIndex = -1;

		if (index === 0) return 0;

		props.completed.map(path => this.indexForStepId(path)).forEach(i => {
			lastCompletedIndex = Math.max(lastCompletedIndex, i);
		});

		return Math.min(lastCompletedIndex + 1, props.steps.length - 1);
	};

	canAdvanceStep = (): boolean => {
		const index = this.currentStepIndex;
		const { steps } = this.props;
		const config = steps[index];
		const requiresSubmit = (config.requiresValidation || config.requiresSelection) || false;
		const path = this.stepIdForIndex(index);
		const completed = this.props.completed.indexOf(path) !== -1;

		return (index < steps.length - 1 && (!requiresSubmit || completed));
	};

	indexForStepId = (id: string, all: boolean = false): number => {
		if (all) {
			return this.props.steps.map(s => s.id).indexOf(id);
		}
		return this.visibleSteps.map(s => s.id).indexOf(id);
	};

	stepIdForIndex = (index: number, all: boolean = false): string => {
		const id = all ? this.props.steps[index].id : this.visibleSteps[index].id;
		if (!id) throw new Error(`Invalid string id provided for step at index ${index}`);

		return id;
	};

	navigation = (): any[] => {
		return this.visibleSteps.map((step) => ({ title: step.title }));
	};

	handleStepReset = (key: string) => {
		const index = this.indexForStepId(key, true);
		if (index !== -1) {
			this.skip = this.skip.filter(id => (this.indexForStepId(id) > index));
		}
	};

	render() {
		const index = this.currentStepIndex;
		const steps = this.visibleSteps;

		if (!steps || steps.length == 0) {
			return <p>No steps available.</p>
		}

		const config = steps[index];
		const stepRequiresValidation = (typeof config.requiresValidation === 'undefined') ? false : config.requiresValidation;
		const containerClass = this.props.fluid === true ? 'container-fluid' : 'container';
		const stepConfig = {...config, stateData: this.props.data};

		const navigation = (<StepNavigation 
			steps={this.navigation()} 
			stateData={this.props.data}
			completed={this.completedStepIndexes}
			selectedIndex={index} 
			maxIndex={this.state.maxStepIndex}
			goToStepIndex={(index) => this.goToStepIndex(index)}
		/>);

		const containerStyles: any = {};
		// if (this.state.isNavSticky && this.navElement) {
		// 	const b = this.navElement.getBoundingClientRect();
		// 	if (b) {
		// 		containerStyles.paddingTop = `${b.height}px`;
		// 	}
		// }

		return (
			<div>
				<Header title={this.props.title} basepath={this.props.rootpath || this.props.basepath } links={this.props.links} containerClass={containerClass} />
				<Sticky sides={{ top: 0 }} onChange={(active) => this.setState({ isNavSticky: active })}>
					<div className="awpw-navigation" ref={(el) => this.navElement = el}>
						<div className={containerClass}>
							<div id="wizard-nav" className="row">
								<div className="navigation__left col-md-2 col-xs">
									<button className="btn btn-outline-dark" onClick={this.goPrevious} disabled={index === 0}>Previous</button>
								</div>
								<div className="navigation__center col-md-8">{navigation}</div>
								<div className="navigation__right col-md-2 col-xs">
									<button className="btn btn-outline-dark" onClick={this.goNext} disabled={!this.canAdvanceStep()}>Next</button>
								</div>
								<div className="navigation__center-mobile col-sm-12">{navigation}</div>
							</div>
						</div>
					</div>
				</Sticky>
				<div className="awpw-content">
					<div className={containerClass} style={containerStyles}>
						<Step 
							{...stepConfig} 
							goNext={this.goNext} 
							goToStep={this.skipTo} 
							onReset={this.handleStepReset} 
							previewElement={this.props.previewElement}
						/>
					</div>
				</div>
				<Footer containerClass={containerClass} />
			</div>
		);
	}
}

const mapStateToProps = (state: StoreState, props: any): StateProps => {
	return {
		...props,
		activeStepIndex: state.wizard.stepIndex,
		steps: state.wizard.steps,
		completed: state.wizard.completed,
		data: state.wizard.data
	};
};

const mapDispatchToProps = (dispatch: Dispatch<actions.WizardAction>): DispatchProps => {
	return {
		setStepIndex: (index: number) => dispatch(actions.setStepIndex(index)),
		completeStep: (path: string, index: number) => dispatch(actions.completeStep(path, index)),
		saveFieldData: (key: string, data: any) => dispatch(actions.saveFieldData(key, data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
