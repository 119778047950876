/// <reference path="../../typings/react-jsonschema-form.d.ts" />
import React from 'react';
import { JSONSchema4 } from "json-schema";
import Form, { UiSchema } from 'react-jsonschema-form';
import { get, set, unset, merge, mergeWith, isPlainObject, isFunction, isArray, cloneDeep } from 'lodash';
import { ContentItem } from '../../types';
import ObjectFieldTemplate from '../form/ObjectFieldTemplate';
import ArrayFieldTemplate from '../form/ArrayFieldTemplate';
import ErrorListTemplate from '../form/ErrorListTemplate';
import DateSelectorField from '../form/DateSelectorField';
import DateRangeField from '../form/DateRangeField';
import CollapsibleField from '../form/CollapsibleField';
import ColorPickerField from '../form/ColorPickerField';
import { addClassName } from '../../../utils';

const getDefaults = (obj: any, prefix?: string): any => {
	let result: any = {};
	const parse = (obj: any, prefix: string = ''): any => {
		Object.keys(obj).forEach(k => {
			const key = (prefix ? `${prefix}.${k}` : k).replace(/(properties|items)\./g, '');
			const v = obj[k];

			if (k === 'default') {
				set(result, key, v);
			} else if (isPlainObject(v)) {
				if (undefined != v.default) {
					set(result, key, v.default);
				} else if (v.items && v.items.default) {
					set(result, key, v.items.default);
				} else {
					parse(v, key);
				}
			}
		});
	};
	parse(obj);

	return result;
};

const transform = (obj: any, schema: any) => {
	Object.keys(obj).forEach(key => {
		const prop = get(schema, `properties.${key}`) || {};
		// console.log('transform', key, prop);

		if (prop.properties) {
			transform(obj[key], prop);
		} else if (prop.type) {
			const type = prop.type;
			const val = obj[key];

			if (type === 'string') {
				obj[key] = `${val}`;
			} else if (type === 'number') {
				obj[key] = parseFloat(val);
			} else if (type === 'array') {
				const items = get(prop, 'items.properties');
				const values = get(obj, key);

				// console.log('array - '+key, obj, items);
				if (values && values.length > 0) {
					values.forEach((v: any) => {
						Object.keys(v).forEach((vk: string) => {
							const itemVal = v[vk];
							const itemType = get(items, `${vk}.type`);
							if (itemType === 'string') {
								set(v, vk, `${itemVal}`);
							} else if (itemType === 'number') {
								set(v, vk, parseFloat(itemVal));
							}
							// console.log('...'+vk, itemType);

						});
					});
				}
			}
		}
	});
};

export interface Props extends ContentItem {
	responder?: any,
	dataFormatter?: Function,
	handleChange: (data: any) => void,
	handleSubmit: (data: any) => void
};

export type State = {
	schema: JSONSchema4,
	uiSchema: UiSchema,
	formData: any,
	validates: boolean
};

export type FormModule = {
	isValidated: () => boolean
};

export default class FormWrapper extends React.Component<Props, State> {
	state = {
		schema: {},
		uiSchema: {},
		formData: {},
		validates: false
	};

	_submitted: boolean = false;
	private formRef = React.createRef<any>();

	componentDidMount() {
		const conditions = this.props.content.conditionals;
		const schema: JSONSchema4 = {...this.props.content.schema};
		const uiSchema: UiSchema = {...this.props.content.uiSchema};

		// merge in form default values into formData state
		let defaults = getDefaults(schema);
		// use `mergeWith` instead of `merge` so that arrays are replaced instead of merged together using the callback function
		let formData: any = mergeWith({}, defaults, this.props.data, (obj: any, source: any) => {
			if (isArray(obj)) {
				return source;
			}
		});

		// transform the initial state values to the data types required by each form property as needed
		// to prevent form validation errors when trying to submit a form populated by query param values
		if (schema) {
			transform(formData, schema);
		}

		// this.setState(() => ({
		// 	schema,
		// 	uiSchema,
		// 	formData
		// }));

		const onStateUpdate = () => {
			if (this.props.handleChange) {
				this.props.handleChange(this.state.formData);
			}
		}
		
		if (conditions) {
			// apply the conditions without overriding state data values
			const result = this._handleConditionals(conditions, schema, uiSchema, formData, false);
			this.setState({
				schema: result.schema,
				uiSchema: result.uiSchema,
				formData: result.data
			}, () => {
				onStateUpdate();
			});
		} else {
			this.setState({
				schema,
				uiSchema,
				formData
			}, () => {
				onStateUpdate();
			});
		}
	}

	submit() {
		if (this.formRef.current) {
			this.formRef.current.submit();
		}
	}

	// componentWillUnmount() {
	// 	console.log('unmounting form, saving existing state to parent wizard', this._submitted, this.state.formData);
	// 	if (!this._submitted && this.props.handleSubmit) {
	// 		this.props.handleSubmit(this.state.formData);
	// 	}
	// }

	isValidated(): boolean {
		return this.state.validates;
	}

	handleChange = (data: any) => {
		const { formData } = data;
		const conditions = this.props.content.conditionals;
		const schema: JSONSchema4 = {...this.state.schema};
		const uiSchema: UiSchema = {...this.state.uiSchema};

		data = { ...formData };

		const formatter = this.props.dataFormatter;
		if (formatter && isFunction(formatter)) {
			data = formatter(data);
		}
		
		if (conditions) {
			// update form data based on changes to conditional fields
			const result = this._handleConditionals(conditions, schema, uiSchema, data);
			data = result.data;
		}

		this.setState(() => ({ formData: data }));

		if (this.props.handleChange) {
			this.props.handleChange(data);
		}
	};

	onSubmit = (result: any) => {
		const formData: any = result.formData;
		this.setState(() => ({ validates: true }));
		this._submitted = true;
		// console.log('FORM SUBMITTED', formData);
		
		if (this.props.handleSubmit) {
			this.props.handleSubmit(formData);
		}
	};

	onError = () => {
		console.log('form error');
	};

	uiSchema(): {[key: string]: any} {
		return {
			'ui:widget': DateRangeField
		};
	}

	fields(): {[key: string]: any} {
		return {
			date: DateSelectorField,
			daterange: DateRangeField,
			collapsible: CollapsibleField,
			color: ColorPickerField
		};
	}

	_handleConditionals(conditions: any, schema: any, ui: any, data: any = {}, overwrite: boolean = true): { schema: any, uiSchema: any, data: any } {
		schema.properties = schema.properties || {};
		data = data || {};

		Object.keys(conditions).forEach(key => {
			const opts = conditions[key];
			let val = get(data, key);
			let storedVal = get(this.state.formData, key);
			let match: any = opts[val];
			// console.log('COND', key, match, opts, val, storedVal);

			let uiOverrides: any;
			if (match && isPlainObject(match)) {
				if (match.schema && match.ui) {
					uiOverrides = match.ui;
					match = match.schema;
				}
	
				if (`${val}` !== `${storedVal}`) {	
					Object.keys(match).forEach(mk => {
						const prop = mk.replace(/\.properties\./g, '.');
						let schemaProp = `properties.${prop.split('.').join('.properties.')}`;
						const field = match[mk];
						// const fieldDefault = get(schema, `properties.${mk}.default`);

						if (isPlainObject(field) && /\.properties$/.test(schemaProp)) {
							schemaProp = `${schemaProp}.properties`;
						}
	
						// set(updates, schemaProp, field);
						const origFieldSchema = cloneDeep(get(schema, schemaProp)) || {};
						const newFieldSchema = (origFieldSchema.properties && !field.properties) ? { properties: field } : field;
						// updates = merge(updates, get(schema, `properties.${schemaProp}.properties`), set({}, `${schemaProp}.properties`, field));
						// console.log('COND change', schemaProp, field, fieldDefault, updates);
	
						// need to save state with removed property before updating and saving again to ensure form is re-rendered
						if (field.type || Object.keys(field).length > 1 || !get(schema, schemaProp)) {
							unset(schema, schemaProp);
							this.setState(() => ({ schema }));
						}

						set(schema, schemaProp, merge({}, origFieldSchema, newFieldSchema));
						// console.log(schemaProp, prop, '-----', schema, '-----', origFieldSchema, newFieldSchema, 'result', get(schema, schemaProp));
						
						if (overwrite) {
							// clear out previously stored value from formData for this key
							if (get(data, prop) !== undefined) {
								unset(data, prop);
								this.setState(() => ({ formData: data }));
							}
							// console.log('COND update', prop, field.default, fieldDefault);
	
							// update formData value based on this field's default value
							if (isPlainObject(field)) {
								if (field.default) {
									set(data, prop, field.default);
								} else {
									Object.keys(field).forEach((key) => {
										const f = field[key];
										if (f.default) {
											set(data, `${prop}.${key}`, f.default);
										}
									});
								}
							} else {
								set(data, prop, field);
							}
						}
					});
				}
			}

			if (isPlainObject(uiOverrides)) {
				ui = merge(ui, uiOverrides);
			}
		});

		return { schema, uiSchema: ui, data };
	}

	render() {
		const className: string = addClassName(this.props.className, 'content__form');
		const fields = this.fields();
		const uiSchema = this.uiSchema();
		const props: any = {
			schema: this.state.schema,
			uiSchema: {...this.state.uiSchema, uiSchema},
			fields,
			ArrayFieldTemplate,
			ErrorList: ErrorListTemplate
		};

		const formData = { ...this.state.formData, ...this.props.stateData[this.props.dataKey] };

		const formRenderer = () => (
			<Form {...props} 
				ref={this.formRef}
				formData={formData} 
				onChange={this.handleChange}
				onSubmit={this.onSubmit} 
				onError={this.onError}
			>
				<React.Fragment />
			</Form>
		);

		if (this.props.responder) {
			const stateData = {...this.props.stateData, [this.props.dataKey]: {...this.state.formData}};
			const responder = this.props.responder;
			return (
				<div id={this.props.id} className={className}>
					<div className="row">
						<div className="col-lg-6 col-md-12">
							{formRenderer()}
						</div>
						<div className="col-lg-6 .d-none .d-lg-block .d-xl-block">
							{isFunction(responder) ? responder(stateData) : responder}
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div id={this.props.id} className={className}>
					{formRenderer()}
				</div>
			);
		}
	}
}
