import React from 'react';
import Interweave from 'interweave';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { drop, isFunction, isPlainObject, isString } from 'lodash';
import { ContentItem } from '../../types';
import { addClassName } from '../../../utils';

const getListStyle = (isDraggingOver: boolean): any => ({
    background: isDraggingOver ? '#cfefff' : '#efefef',
    borderColor: isDraggingOver ? '#7dd2ff' : '#ccc'
});

const getItemStyle = (isDragging: boolean, draggableStyle: any): any => {
    draggableStyle.margin = '2px';
    return {
        ...draggableStyle
    };
};

interface ListItem {
    id: string;
    value: string | number;
    title?: string;
    image?: string;
    description?: string;
    renderer?: (data: any) => any;
}

interface ListStateItem extends ListItem {
    options?: any;
}

interface ListProps {
    id: string;
    title?: string;
    description?: string;
    publishState?: boolean;
    enableDoubleClick?: boolean;
    enableClearAll?: boolean;
    itemRenderer?: (item: any) => React.ReactElement;
    items: ListItem[];
    groups?: {
        title: string;
        value: string;
        items: ListItem[];
    }[];
}

export interface Props extends ContentItem {
	id?: string;
    className?: string;
    lists: ListProps[];
    handleChange?: (data: any) => void;
}

export interface State {
    items: { [key: string]: ListStateItem[] };
    selectedGroups: { [key: string]: string };
    publishState: { [key: string]: boolean };
}

export interface ItemCellProps {
    listId: string;
    cellIndex: number;
    title: string;
    description?: string;
    imageUrl?: string;
    state?: any;
    onChange?: (data: any) => void;
    onDelete?: (listId: string, cellIndex: number) => void;
}

export const ItemCell = (props: ItemCellProps): React.ReactElement => {
    const { listId, cellIndex, title, imageUrl, description, onDelete = () => {} } = props;
    return (
        <div className="dragdrop__item-actions">
            {imageUrl && <div className="dragdrop__item-thumb"><img src={imageUrl} /></div>}
            <div className="dragdrop__item-content">
                <div className="dragdrop__item-title">{title}</div>
                {description && <p className="dragdrop__item-desc">{<Interweave content={description} />}</p>}
            </div>
            <div className="dragdrop__item-btn dragdrop__item-btn-delete" onClick={() => onDelete(listId, cellIndex)}>
                <svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                    <path d="m26.844 78.949c0 2.8633 2.3203 5.1836 5.1836 5.1836h35.949c2.8633 0 5.1797-2.3203 5.1797-5.1836v-47.18h-46.312zm38-56.98v-5.1094c0-0.55078-0.44531-0.99219-0.99609-0.99219h-27.695c-0.55078 0-0.99609 0.44531-0.99609 0.99219v5.1094h-11.703v4.9766h53.094v-4.9766z"/>
                </svg>
            </div>
        </div>
    );
};

class DragDrop extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        // split up initial list items by list to store as initial state
        const itemsByList: { [key: string]: ListItem[] } = {};
        const listsByKey: { [key: string]: ListProps } = {};
        const lists = props.lists || [];
        lists.forEach(({ id, items, groups }) => {
            if (groups) {
                if (isFunction(groups)) groups = groups();
                const group: any = groups[0] || {};
                itemsByList[id] = isFunction(group.items) ? group.items() : group.items;
            } else {
                itemsByList[id] = isFunction(items) ? items() : items;
            }

            listsByKey[id] = {
                id, items, groups
            };
        });

        // update initial list items based on stateData if provided
        const stateData = this.props.data;
        if (stateData) {
            const spliceListItem = (lists: { [key: string]: ListItem[]}, value: string): any => {
                let result: any;

                Object.keys(lists).forEach((key) => {
                    let available: ListItem[] = [ ...lists[key] ];

                    // if list has groups, we need to flatten them into a single array to check for item
                    // available rather than just a single group
                    const { groups } = listsByKey[key];
                    if (groups) {
                        available = groups.reduce((result: ListItem[], group: any) => {
                            return result.concat(group.items || []);
                        }, []);
                    }
                    
                    available.forEach((item, index) => {
                        if (item.id === value) {
                            result = lists[key].splice(index, 1);
                            // if result is empty, then its item is in a different group so we need to 
                            // find it from the `available` array and return it instead
                            if (result.length === 0) {
                                result = available.filter((v) => v.id === item.id);
                                result = result[0] || [];
                            }
                        }
                    });
                });

                return result;
            };

            // need to splice out items from lists that exist in other lists based on stateData
            Object.keys(stateData).forEach((key) => {
                let vals = stateData[key];
                if (!Array.isArray(vals)) {
                    if (isPlainObject(vals)) {
                        vals = Object.keys(vals);
                    } else {
                        vals = [vals];
                    }
                }

                itemsByList[key] = vals.map((v: string) => spliceListItem(itemsByList, v))
                    .filter((v: any) => typeof v !== 'undefined')
                    .map((v: any) => {
                        const item: any = v[0] || v;
                        const listStateData = stateData[key] || {};
                        // add list item options if defined in the state
                        if (listStateData[item.id]) {
                            item.options = { ...item.options, ...listStateData[item.id] };
                        }
                        return item;
                    });
            });
        }

        this.state = { 
            items: itemsByList,
            selectedGroups: Object.keys(listsByKey).reduce((result: any, key) => {
                const list = listsByKey[key];
                result[key] = list.groups && list.groups.length > 0 ? list.groups[0].value : undefined;
                return result;
            }, {}),
            publishState: lists.reduce((result: any, list) => {
                result[list.id] = (list.publishState !== false);
                return result;
            }, {})
        };
    }

    componentDidMount() {
        // send up current state when mounted
        this.handleChange();
    }

    handleChange = () => {
        this.props.handleChange(Object.keys(this.state.items).reduce((result: any, key: string) => {
            if (this.state.publishState[key] === true) {
                result[key] = this.state.items[key].reduce((val: any, item) => {
                    const id = item.value || item.id;
                    val[id] = item.options || {};
                    return val
                }, {});
            }
            return result;
        }, {}));
    };

    handleCellStateChange = (listId: string, cellIndex: number, data: any) => {
        const listItems = this.state.items[listId];
        if (listItems && cellIndex < listItems.length) {
            listItems[cellIndex].options = { ...data };

            this.setState((prevValue) => ({
                ...prevValue,
                items: {
                    ...prevValue.items,
                    [listId]: listItems
                }
            }), () => {
                if (this.props.handleChange) {
                    this.handleChange();
                }
            });
        }
    };
    
    onDragEnd = (result: any) => {
        const { source, destination } = result;

        // dropped outside the target area
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            // reorder
            const items = Array.from(this.state.items[source.droppableId]);
            const [ removed ] = items.splice(source.index, 1);
            items.splice(destination.index, 0, removed);

            this.setState((prevValue) => ({
                ...prevValue,
                items: {
                    ...prevValue.items,
                    [source.droppableId]: items
                }
            }), () => {
                if (this.props.handleChange) {
                    this.handleChange();
                }
            });

        } else {
            // move
            const sourceCopy: ListItem[] = Array.from(this.state.items[source.droppableId]);
            const destCopy: ListItem[] = Array.from(this.state.items[destination.droppableId]);
            const [ removed ] = sourceCopy.splice(source.index, 1);
            destCopy.splice(destination.index, 0, removed);

            this.setState((prevValue) => ({
                ...prevValue,
                items: {
                    ...prevValue.items,
                    [source.droppableId]: sourceCopy,
                    [destination.droppableId]: destCopy
                }
            }), () => {
                if (this.props.handleChange) {
                    this.handleChange();
                }
            });
        }
    };

    onItemDoubleClick = (droppableId: string, index: number) => {
        // need to determine destination droppable target to insert this clicked item into
        // we'll just use the list with the next index, or the previous one if the item is in the last list
        const listIds = Object.keys(this.state.items);
        let targetIndex = listIds.indexOf(droppableId);
        targetIndex += (targetIndex === listIds.length - 1) ? -1 : 1;
        targetIndex = Math.max(0, Math.min(targetIndex, listIds.length - 1));
        
        const destinationId = listIds[targetIndex];
        this.onDragEnd({
            source: {
                droppableId,
                index
            },
            destination: {
                droppableId: destinationId,
                index: this.state.items[destinationId].length
            }
        });
    };

    onGroupChange = (droppableId: string, value: string) => {
        let items = this.getGroupListItems(droppableId, value);

        // splice out items that already exist in other lists
        const omitIds = Object.keys(this.state.items).filter((key) => key !== droppableId).reduce((result, key) => {
            const itemKeys = this.state.items[key].map((item) => item.id);
            return result.concat(itemKeys);
        }, []);

        // filter out group items whose id matches one returned in our omit list
        items = items.filter((item) => omitIds.indexOf(item.id) === -1);

        this.setState((prevState) => ({
            ...prevState,
            selectedGroups: {
                ...prevState.selectedGroups,
                [droppableId]: value
            },
            items: {
                ...prevState.items,
                [droppableId]: items
            }
        }));
    };

    onReset = (droppableId: string) => {
        const newItems = { ...this.state.items };

        // remove all items from the target list
        newItems[droppableId] = [];

        // update remaining lists associated with groups so removed items get reinserted
        this.props.lists.filter((v) => v.id !== droppableId).forEach((info) => {
            const { id, groups } = info;
            if (groups) {
                newItems[id] = this.getGroupListItems(id, this.state.selectedGroups[id]);
            }
        });

        this.setState((prevState) => ({
            ...prevState,
            items: newItems
        }), () => {
            if (this.props.handleChange) {
                this.handleChange();
            }
        });
    }

    handleDeleteItem = (listId: string, index: number) => {
        this.setState((prevValue) => {
            const listIds = Object.keys(prevValue.items);
            const newValue = { ...prevValue };

            // remove item from list and re-insert into other list
            const item = newValue.items[listId].splice(index, 1);
            if (item && item.length === 1) {
                let added = false;
                listIds.forEach((id) => {
                    if (!added && id !== listId) {
                        newValue.items[id].push(item[0]);
                        added = true;
                    }
                });
            }
            return newValue;
        }, () => {
            if (this.props.handleChange) {
                this.handleChange();
            }
        });
    };

    private getGroupListItems(droppableId: string, name: string): ListItem[] {
        let items: any[];

        const list: any = this.props.lists.filter((info) => info.id === droppableId && info.groups);
        if (list.length > 0) {
            const { groups: listGroups } = list[0];
            const groups = isFunction(listGroups) ? listGroups() : listGroups;
            groups.forEach((group: any) => {
                if (group.value === name) {
                    items = group.items || [];
                }
            });
        }

        return items;
    }

    render() {
        const { lists, title, description } = this.props;
        const className: string = addClassName(this.props.className, 'content__dragdrop');

        const generateGroupControl = (id: string, groups: any[]): any => {
            if (!groups) {
                return <></>;
            }
            if (isFunction(groups)) {
                groups = groups();
            }
            return (
                <select className="form-control dragdrop__list-groups" onChange={(e: any) => this.onGroupChange(id, e.currentTarget.value)}>
                    {groups.map((item) => (
                        <option key={item.value} value={item.value}>{item.title}</option>    
                    ))}
                </select>
            );
        };

        let hasGroups = false;
        lists.forEach((list) => {
            if (list.groups) hasGroups = true;
        });
        
        return (
            <>
                {(title || description) && (
                    <div className="content__section-divider">
                        {title && <div className="content__section-title">{title}</div>}
                        {description && <div className="content__section-desc">{description}</div>}
                    </div>
                )}
                <div id={this.props.id} className={className}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        {lists.map(({ 
                            id: listId, 
                            title: listTitle, 
                            description: listDesc, 
                            groups, 
                            itemRenderer,
                            enableDoubleClick, 
                            enableClearAll 
                        }) => (
                            <div key={listId} className={`dragdrop__list-container dragdrop__list-${listId}`} style={{ width: `${100 / lists.length}%` }}>
                                <div>
                                    {(listTitle || listDesc) && (
                                        <div className="dragdrop__list-header">
                                            {listTitle && <div className="dragdrop__list-title">{listTitle}</div>}
                                            {listDesc && <div className="dragdrop__list-desc">{listDesc}</div>}
                                        </div>
                                    )}
                                    {generateGroupControl(listId, groups)}
                                    {enableClearAll && (
                                        <div className="dragdrop__list-actions">
                                            <a className="text-sm" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.onReset(listId);
                                            }}>Clear All</a>
                                        </div>
                                    )}
                                    <Droppable droppableId={listId}>
                                        {(provided, snapshot) => (
                                            <div
                                                className="dragdrop__target"
                                                { ...provided.droppableProps }
                                                ref={provided.innerRef}
                                                style={{
                                                    ...getListStyle(snapshot.isDraggingOver)
                                                }}
                                            >
                                                {this.state.items[listId].map((item, index) => {
                                                    const { id, value, title, image, options, renderer: cellRenderer } = item;
                                                    const cellId: string = id || `${value}`;                                
                                                    const cellProps: ItemCellProps = { 
                                                        listId, 
                                                        cellIndex: index, 
                                                        title,
                                                        imageUrl: image,
                                                        state: options,
                                                        onChange: (cellData: any) => this.handleCellStateChange(listId, index, cellData),
                                                        onDelete: () => this.handleDeleteItem(listId, index),
                                                        ...item 
                                                    };
                                                    let cellContent: any;

                                                    // try to get cell content from item/list renderers if defined
                                                    if (cellRenderer) {
                                                        cellContent = cellRenderer(cellProps);
                                                        if (isString(cellContent)) {
                                                            cellContent = (
                                                                <div dangerouslySetInnerHTML={{__html: cellContent}}></div>
                                                            );
                                                        }
                                                    } else if (itemRenderer && isFunction(itemRenderer)) {
                                                        cellContent = itemRenderer(cellProps);
                                                    } else {
                                                        cellContent = <ItemCell { ...cellProps } />;
                                                    }

                                                    return (
                                                        <Draggable 
                                                            key={`${listId}-${cellId}`} 
                                                            draggableId={cellId} 
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    className="dragdrop__item"
                                                                    ref={provided.innerRef}
                                                                    { ...provided.draggableProps }
                                                                    { ...provided.dragHandleProps }
                                                                    onDoubleClick={() => {
                                                                        if (enableDoubleClick) {
                                                                            this.onItemDoubleClick(listId, index);
                                                                        }
                                                                    }}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    {cellContent}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                    
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        ))}
                    </DragDropContext>
                </div>
            </>
        )
    }
};

export default DragDrop;