import Action from '../Action';
import { StepData, AppMetadata } from '../types';
import * as constants from '../constants';

export interface Init {
	type: '@@INIT'
}

export interface SetAccessKeys {
	type: constants.SET_ACCESS_KEYS,
	payload: { id: string, key: string }
}

export interface SetAccountApps {
	type: constants.SET_ACCOUNT_APPS,
	payload: { apps: AppMetadata[] }
}

// Combine all actions into a single action type
export type AccountAction = Init | SetAccessKeys | SetAccountApps;

export const setAccessKeys = (id: string, key: string): SetAccessKeys => ({
	type: constants.SET_ACCESS_KEYS,
	payload: {
		id,
		key
	}
});

export const setAccountApps = (apps: AppMetadata[]): SetAccountApps => ({
	type: constants.SET_ACCOUNT_APPS,
	payload: {
		apps
	}
});