import Action from '../Action';
import { StepData } from '../types';
import * as constants from '../constants';

export interface Init {
	type: '@@INIT'
}

// Updating current step index
// export type SetStepIndexPayload = { index: number };
export interface SetStepIndex {
	type: constants.SET_STEP_INDEX,
	payload: { index: number }
}

export interface CompleteStep {
	type: constants.COMPLETE_STEP,
	payload: { path: string, index: number }
}

export interface ResetStep {
	type: constants.RESET_STEP,
	payload: { path: string, index: number }
}

// Saving user data for a specific step
// export type SaveStepDataPayload = { index: number, data: any };
export interface SaveStepData {
	type: constants.SAVE_FIELD_DATA,
	payload: { key: string, data: StepData }
}

// Combine all actions into a single action type
export type WizardAction = Init | SetStepIndex | CompleteStep | ResetStep | SaveStepData;

export const setStepIndex = (index: number): SetStepIndex => ({
	type: constants.SET_STEP_INDEX,
	payload: {
		index
	}
});

export const completeStep = (path: string, index: number): CompleteStep => ({
	type: constants.COMPLETE_STEP,
	payload: {
		path,
		index
	}
});

export const resetStep = (path: string, index: number): ResetStep => ({
	type: constants.RESET_STEP,
	payload: {
		path,
		index
	}
});

export const saveFieldData = (key: string, data: any): SaveStepData => ({
	type: constants.SAVE_FIELD_DATA,
	payload: {
		key,
		data
	}
});