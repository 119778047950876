
export const SET_STEP_INDEX: 'SET_STEP_INDEX' = 'SET_STEP_INDEX';
export type SET_STEP_INDEX = typeof SET_STEP_INDEX;
// export type SET_STEP_INDEX = { index: number };

export const COMPLETE_STEP: 'COMPLETE_STEP' = 'COMPLETE_STEP';
export type COMPLETE_STEP = typeof COMPLETE_STEP;

export const RESET_STEP: 'RESET_STEP' = 'RESET_STEP';
export type RESET_STEP = typeof RESET_STEP;

export const SAVE_FIELD_DATA: 'SAVE_FIELD_DATA' = 'SAVE_FIELD_DATA';
export type SAVE_FIELD_DATA = typeof SAVE_FIELD_DATA;
// export type SAVE_FIELD_DATA = { key: string, data: any };

export const SET_ACCESS_KEYS: 'SET_ACCESS_KEYS' = 'SET_ACCESS_KEYS';
export type SET_ACCESS_KEYS = typeof SET_ACCESS_KEYS;

export const SET_ACCOUNT_APPS: 'SET_ACCOUNT_APPS' = 'SET_ACCOUNT_APPS';
export type SET_ACCOUNT_APPS = typeof SET_ACCOUNT_APPS;