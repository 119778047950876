import React from 'react';
import ReactDOM from 'react-dom';
import Interweave from 'interweave';
import { isElement, isString } from 'lodash';
import Container from './core/Container';
import Ajax from './core/Ajax';
import { AppProps, AppState, StepConfig } from './core/types';
import { PRODUCTS, getProduct } from './config/products';

import 'normalize.css/normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/styles.scss';
import { logo } from './icons';
import { setDocumentLink, setDocumentMeta } from './utils';

const ProductSelector = ({ 
	products = [], 
	onSelect = () => {} 
}: any): React.ReactElement => {
	document.body.classList.add('body__product');
	return (
		<>
			<div className="awpw-content content__product-selector">
				<div className="content__product-selector-header">
					<div className="center">
						<h1 className="header__title">
							<div className="header__logo">{logo()}</div>
							<div className="header__seperator" />
							<div className="header__name">Wizards</div>	
						</h1>
						<p className="header__lead push-top">Quickly get started with our products and services by using our series of wizards to easily get the weather data and imagery you need.</p>
					</div>
				</div>
				<div className="content__collection">
					<div className="collection__items collection-products">
						{products.map(({ id, title, image, description }: any) => (
							<div key={id} className="collection__item" onClick={() => onSelect(id)}>
								<p className="collection__item-title">{title.replace(/^Xweather /i, '')}</p>
								<div className="collection__item-thumb"><img src={image} /></div>
								<p className="collection__item-desc">{<Interweave content={description} />}</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="awpw-footer">
				<div className="container-fluid">
					<footer className="row">
						<div className="col-md-12">
							<p>&copy; {new Date().getFullYear()} Vaisala Xweather. <a href="https://www.xweather.com/privacy" target="_blank">Privacy</a> + <a href="https://www.xweather.com/terms" target="_blank">Terms</a></p>
						</div>
					</footer>
				</div>
			</div>
		</>
	);
};

export class App extends React.Component<AppProps, AppState> {
	state = {
		steps: [] as StepConfig[],
		loading: false
	};

	componentDidMount() {
		if (this.props.steps) {
			this.setState(() => ({ steps: this.props.steps }));
		} else if (this.props.stepSource && this.props.stepSource.length > 0) {
			this.setState(() => ({ loading: true }));
			Ajax.get(this.props.stepSource).then((res: string) => {
				const json = JSON.parse(res);
				if (json && json.length > 0) {
					this.setState(() => ({ 
						steps: json,
						loading: false
					}));
				}
			});
		}
	}

	render() {
		return (
			<div>
				{this.state.steps.length > 0 ? (
					<Container {...this.props} steps={this.state.steps} />	
				) : (
					<ProductSelector products={PRODUCTS} onSelect={this.props.onSelectProduct} />
				)}
			</div>
		);
	}
}

class ProductWizard {
	target: any;

	constructor(target: any, config: AppProps = { basepath: '/', appendDataQueryString: false }) {
		const product = config.product;

		if (isString(target)) {
			target = document.querySelector(target);
		}
		this.target = target;
		
		if (typeof product === 'string') {
			this.loadProduct(product);
			return;
		}

		if (isElement(target)) {
			ReactDOM.render(<App {...config} onSelectProduct={(id: string) => this.goToProduct(id)} />, target);
		}
	}

	goToProduct(id: string) {
		const validIds = PRODUCTS.map((p) => p.id);

		if (validIds.indexOf(id) !== -1) {
			const prefix = /\/product\//.test(document.location.href) === false ? 'product/' : '';
			document.location.href = `./${prefix}${id}/`;
		} else {
			throw new Error(`No wizard module found for product {${id}}`);
		}
	}

	loadProduct(id: string) {
		const target = this.target;
		const config = getProduct(id);

		if (config && config.loader) {
			const { loader, title, canonical, metaDescription } = config;
			loader().then((module: any) => {
				const component = module.default;

				if (title) {
					document.title = /^Xweather/.test(title) === false ? `Xweather - ${title}` : title;
				}

				if (canonical) {
					setDocumentLink('canonical', 'href', canonical);
				}

				if (metaDescription) {
					setDocumentMeta('description', 'content', metaDescription);
				}
				
				if (isElement(target)) {
					ReactDOM.render(React.createElement(component), target);
				}
			}).catch((error: Error) => {
				console.error(`[Wizard] Failed to load product module for {${id}}`, error);
			});
		} else {
			throw new Error(`No wizard module found for product {${id}}`);
		}
	}
}

export default ProductWizard;