import React from 'react';
import { connect, Dispatch } from 'react-redux';
import uuid from 'uuid';
import { cloneDeep, isFunction, isPlainObject, isString, merge } from 'lodash';
import { ContentConfig } from '../types/index';
import { StoreState } from '../types/store';
import * as actions from '../actions/wizard';
import { widgetMap } from './modules';

export type StateProps = {
	items: ContentConfig[],
	stepId: string,
	index: number,
	data?: any,
	stateData?: any,
	account?: {
		id: string,
		key: string
	},
	requiresValidation: boolean,
	requiresSubmit?: boolean,
	dependsOnState: boolean,
	onDataChange?: (key: string, data: any, callback?: () => void) => void,
	onSubmit?: Function,
	onDone?: Function,
	onReset?: Function
};

export type DispatchProps = {
	saveFieldData: (key: string, data: any) => void,
	resetStep: (path: string, index: number) => void
};

export type State = {
	stepId: string;
	data: { [key: string]: any };
	stepData: { [key: string]: any };
	components: { [key: string]: any };
};

export class StepContent extends React.Component<StateProps & DispatchProps, State> {
	_components: { [key: string]: any } = {};
	_activeKeys: string[] = [];

	constructor(props: any) {
		super(props);

		this.state = {
			stepId: props.stepId,
			data: { ...props.stateData },
			stepData: {},
			components: {}
		};
	}

	componentDidUpdate() {
		if (this.props.stepId !== this.state.stepId) {
			this._components = {};
			this.setState({ stepId: this.props.stepId }, () => {
				// this._components = [];
			});
		}
	}

	isValidated(): boolean {
		let allValidated = true;

		Object.keys(this._components).forEach((key) => {
			const { type, element, validates } = this._components[key] || { element: undefined, validates: true };
			// if (element && 'undefined' !== typeof element.isValidated) {
			// 	if (element.isValidated() === false) {
			// 		allValidated = false;
			// 	}
			// }

			// if (type && element) {
			// 	if (type === 'form') {
			// 		console.log('form.isValidated', element.isValidated());
			// 	}
			// }

			if (!validates) {
				allValidated = false;
			}
		});

		return allValidated;
	}

	handleSubmit = () => {
		// activeKeys stores an array of keys for modules that require validation only
		// const stepData = this.props.stateData[this.props.stepId] || {};
		const itemKeys = Object.keys(this._components).concat(Object.keys(this.state.data));
		// if (isPlainObject(stepData)) {
		// 	itemKeys = itemKeys.concat(Object.keys(stepData));
		// }
		const fieldsToSave: any = {};

		// Object.keys(this.state.data)
		let goTo: string;
		let advance = true;
		
		// if this item is a collection, check if a different item was selected and if so, then reset our max 
		// completed step index so all steps afterwards are reset
		itemKeys.forEach((key) => {
			const data = this.props.stateData[key];
			const target = this._components[key];
			let fieldType: string;

			if (target) {
				const { type, element } = target;
				target.validates = true;
				fieldType = type;

				if (type === 'form' && element) {
					// advance = false;
					element.submit();
				}
			}

			if (this.props.data[key] && data !== this.props.data[key]) {
				this.props.resetStep(key, this.props.index);
				if (this.props.onReset) {
					this.props.onReset(key);
				}
			}
	
			// check if we need to reset any existing state data before saving data for this key
			if (this.props.onSubmit) {
				const actions = this.props.onSubmit({...this.props.stateData, [key]: data});
				if (actions) {
					if (actions.update) {
						const fields = actions.update;
						Object.keys(fields).forEach(name => {
							fieldsToSave[name] = fields[name];
							// this.props.saveFieldData(name, fields[name]);
							// this.handleDataChange(name, fields[name]);
						});
					}
					if (actions.goto && isString(actions.goto)) {
						goTo = actions.goto;
					}
				}
			}
	
			if (undefined !== data && fieldType !== 'form') {
				fieldsToSave[key] = data;
				// this.props.saveFieldData(key, data);
			}
		});
		
		const validates = this.isValidated();
		if (validates && advance) {
			const fields = Object.keys(fieldsToSave);
			const count = fields.length;
			fields.forEach((key, index) => {
				const value = fieldsToSave[key];
				this.props.saveFieldData(key, value);
				// force save step data which delays calling `onDone` until all state updates, including those
				// reset updates above, have completed
				// console.log('saving field data', key, value, index, count);
				this.handleDataChange(key, value, () => {
					if (index === count - 1) {
						this.props.onDone(goTo);
					}	
				});
			});
		}
	};

	handleDataChange = (key: string, itemData: any, callback?: () => void) => {
		if (this.props.onDataChange) {
			this.props.onDataChange(key, itemData, callback);
		}
		
		if (!this._components[key]) return;
		if (this._components[key].reset === false) return;

		// if this element's data changes, we need to reset its validation state and step index so that the user is required
		// to go through the normal data validation process for this item before moving on to the next step again
		this._components[key].validates = false;
		this._components[key].reset = true;
		// this.props.resetStep(key, this.props.index);
	};

	renderElement = (item: any) => {
		item = cloneDeep(item);	// clone item so we aren't changing its source properties!
		const { key, type } = item;

		this._components[key] = { 
			type,
			element: null, 
			requiresValidation: false,
			validates: false, 
			reset: false
		};

		const submitHandler = (data?: any, advance: boolean = true) => {
			if (this._components[key]) {
				this._components[key].validates = true;
			}
			
			// if this item is a collection, check if a different item was selected and if so, then reset our max 
			// completed step index so all steps afterwards are reset
			if (item.type === 'collection' && this.props.data[key] && data !== this.props.data[key]) {
				this.props.resetStep(key, this.props.index);
				if (this.props.onReset) {
					this.props.onReset(key);
				}
			}

			// check if we need to reset any existing state data before saving data for this key
			let goTo: string;
			if (this.props.onSubmit) {
				const actions = this.props.onSubmit({...this.props.stateData, [key]: data});
				if (actions) {
					if (actions.update) {
						const fields = actions.update;
						Object.keys(fields).forEach(name => {
							this.handleDataChange(name, fields[name]);
							this.props.saveFieldData(name, fields[name]);
						});
					}
					if (actions.goto && isString(actions.goto)) {
						goTo = actions.goto;
					}
				}
			}

			const validates = this.isValidated();
			if (undefined !== data) {
				this.props.saveFieldData(key, data);
			}
			
			if (validates && advance) {
				// force save step data which delays calling `onDone` until all state updates, including those
				// reset updates above, have completed
				this.handleDataChange(key, data, () => {
					this.props.onDone(goTo);
				});
			}
		};

		// pass data for all steps to the item
		item.stateData = { ...this.props.stateData, account: this.props.account };
		item.dataKey = key;

		// pass step-specific data to the item
		if (this.props.data && this.props.data[key]) {
			let data = this.props.stateData[key];
			if (item.dataFormatter && isFunction(item.dataFormatter)) {
				data = item.dataFormatter(data);
			}
			item = { ...item, data };
		}

		if (item.type === 'account') {
			item.account = this.props.account;
		}

		if (item.type === 'container' || item.type === 'tabs') {
			item.renderer = (props: any) => {
				if (props.component) {
					return (
						<div key={props.id ? props.id : uuid()} className={props.className}>
							{isFunction(props.component) ? props.component(item.stateData, submitHandler) : props.component}
						</div>
					);
				}
	
				const key = props.key || item.id;
				props = {
					...props,
					key: key || uuid()
				}
	
				return this.renderElement(props);
			};
		}

		if (item.type === 'collection') {
			if (item.multiselect) {
				item.submitOnSelect = false;
			}
		}

		const requiresValidation = ['form', 'collection'];
		const supportsChangeEvents = ['form', 'collection', 'dragdrop'];
		if (this.props.requiresValidation || supportsChangeEvents.indexOf(item.type) !== -1) {
			if (requiresValidation.indexOf(item.type) !== -1) {
				this._components[key].requiresValidation = true;
			}

			// assign component reference to access for validation/submission handling
			item.ref = (element: any) => {
				const target = this._components[key];
				if (target) {
					target.element = element;
				}
			};

			// extend item configuration to add handleSubmit handler
			item = {
				...item, 
				handleSubmit: submitHandler, 
				handleChange: (data: any) => this.handleDataChange(key, data)
			};
		}

		// if item contains a component, just return it instead of creating a new element
		if (item.component) {
			// custom components need to be wrapped in a div with the appropriate key to prevent React warnings
			if (item.component instanceof React.Component) {
				return (
					<div key={key}>{item.component}</div>
				);
			} else if (isFunction(item.component)) {
				const changeHandler = (data: any) => this.handleDataChange(key, data);
				return (
					<div key={key}>{item.component(item.stateData, submitHandler, changeHandler)}</div>
				);
			}
		}

		// if item.content is a function, then we need to pass the store data to it for rendering
		if (isFunction(item.content) && item.type !== 'tabs') {
			const itemData = {...this.props.data, account: this.props.account};
			if (item.dependsOnState) {
				item.content = item.content(itemData);
			} else {
				const formatter = item.content;
				item.content = (): string => {
					return formatter(itemData);
				};
			}
		}

		return React.createElement(widgetMap[item.type], item);
	};

	recurseElements = (item: any): any => {
		if (item.type === undefined) return;
		
		if (Object.prototype.toString.call(item) === '[object Array]') {
			return item.map(this.recurseElements);
		} else if (Object.prototype.toString.call(item.content) === '[object Array]') {
			if (item.type == 'container' || item.type == 'collection') {
				return this.renderElement(item);
			} else {
				// need to strip out `content` property from container props
				const content = [...item.content];
				const props: {[key: string]: string} = {
					id: item.id ? item.id : undefined,
					key: item.key ? item.key : undefined,
					className: item.className ? item.className : ''
				};

				return React.createElement('div', props, content.map(this.recurseElements));
			}
		} else {
			return this.renderElement(item);
		}
	};

	render() {
		const items = this.props.items ? this.props.items : [];
		let content: any[] = [];

		items.forEach((item) => {
			let isVisible = true;
			if (typeof item.visible !== 'undefined') {
				isVisible = isFunction(item.visible) ? item.visible(this.state.stepData) : item.visible;
			}
			if (isVisible) {
				const element = this.recurseElements(item);
				content.push(element);
			}
		});

		if (this.props.requiresSubmit) {
			content.push(
				<div key="step-actions" className="collection__action-controls">
					<button type="button" className="btn btn-md btn-primary btn-icon" onClick={this.handleSubmit}>Continue
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 100">
							<path d="M13,0l50.09,50.09L13,100L0,87l36.9-36.9L0,13L13,0L13,0z"/>
						</svg>
					</button>
				</div>
			);
		}

		return React.createElement('div', { className: 'content__items' }, content);
	}
}

const mapStateToProps = (state: StoreState, props: any): StateProps => {
	return {
		...props,
		index: state.wizard.stepIndex,
		data: state.wizard.data,
		account: state.account
	};
};

const mapDispatchToProps = (dispatch: Dispatch<actions.WizardAction>): DispatchProps => {
	return {
		saveFieldData: (key: string, data: any) => dispatch(actions.saveFieldData(key, data)),
		resetStep: (path: string, index: number) => dispatch(actions.resetStep(path, index))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(StepContent);