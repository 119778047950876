import React from 'react';

interface WizardContextType {
	currentStep: { index: number; id: string };
	stateData: any;
	currentData: any;
	setCurrentData: (key: string, data: any) => void;
}

export const DefaultContext: WizardContextType = {
	currentStep: { index: -1, id: undefined },
	stateData: {},
	currentData: {},
	setCurrentData: (key: string, data: any) => {}
}

export const WizardContext = React.createContext(DefaultContext);