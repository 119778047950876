import { StoreState, AccountState } from '../types/store';

const defaultState: StoreState = {
	wizard: {
		stepIndex: 0,
		steps: [],
		completed: [],
		data: {}
	},
	account: {
		id: undefined,
		key: undefined,
		apps: [],
		profile: undefined
	}
};

export default defaultState;