const environment = process.env || {};

export const AUTH_URL = 'https://www.aerisweather.com/data/account/auth';
export const MAPBOX_ACCESS_KEY=environment.MAPBOX_KEY;
export const GOOGLE_ACCESS_KEY=environment.GOOGLE_KEY;

export const AUTH0_DOMAIN = environment.AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = environment.AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = environment.AUTH0_CLIENT_SECRET;
export const AUTH0_WEB_API_AUDIENCE = environment.AUTH0_WEB_API_AUDIENCE;
export const AERIS_WEB_API_BASE_URL = environment.AERIS_WEB_API_BASE_URL;

export const API_WIZARD = {
    ID: environment.API_WIZARD_ID,
    SECRET: environment.API_WIZARD_SECRET
};

export const MAPS_WIZARD = {
    ID: environment.MAPS_WIZARD_ID,
    SECRET: environment.MAPS_WIZARD_SECRET
};

export const MAPAPP_WIZARD = {
    ID: environment.MAPAPP_WIZARD_ID,
    SECRET: environment.MAPAPP_WIZARD_SECRET
};

export const WXBLOX_WIZARD = {
    ID: environment.WXBLOX_WIZARD_ID,
    SECRET: environment.WXBLOX_WIZARD_SECRET
};

export const WIDGETS_WIZARD = {
    ID: environment.WIDGETS_WIZARD_ID,
    SECRET: environment.WIDGETS_WIZARD_SECRET
};