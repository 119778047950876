import * as React from 'react';
import Account from './Account';
import CodeBlock from './CodeBlock';
import Collection from './Collection';
import DragDrop from './DragDrop';
import FormWrapper from './Form';
import Html from './Html';
import HtmlContainer from './HtmlContainer';
import Image from './Image';
import Tabs from './Tabs';

export const widgetMap: {[key: string]: any} = {
	account: Account,
	code: CodeBlock,
	collection: Collection,
	container: HtmlContainer,
	dragdrop: DragDrop,
	form: FormWrapper,
	html: Html,
	image: Image,
	tabs: Tabs
};

export const createComponent = (type: string, config: any): React.ComponentElement<any, any> => {
	return React.createElement(widgetMap[type], config);
};