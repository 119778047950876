import React from 'react';
import uuid from 'uuid';
import { logo } from '../../icons';

export default (props: any) => (
	<div className="awpw-header">
		<div className={props.containerClass}>
			<header className="row">
				<div className="col-md-12">
					<div className="header__logo">
						{logo()}
					</div>
					<h1 className="header__title">{props.title || 'Product Wizard'}</h1>
					{props.links && (
						<ul className="header__links">
							{props.links.map((link: any) => {
								const key = link.key || uuid();
								const href = link.href;
								const rootpath = props.basepath.replace(/\/product\/.+$/, '');
								return <li key={key}><a href={href.replace(/ROOTPATH/, rootpath)} target="_blank">{link.title}</a></li>
							})}
						</ul>
					)}
				</div>
			</header>
		</div>
	</div>
);