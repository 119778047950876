import Action from '../Action';
import defaultState from '../store/defaultState';
import { WizardState } from '../types/store';
import { WizardAction } from '../actions/wizard';
import { SET_STEP_INDEX, SAVE_FIELD_DATA, COMPLETE_STEP, RESET_STEP } from '../constants';

export default (state: WizardState = defaultState.wizard, action: WizardAction): WizardState => {
	let completed: string[] = state.completed;

	switch (action.type) {
		case SET_STEP_INDEX:
			return {...state, stepIndex: action.payload.index};
		case COMPLETE_STEP: {
			const { path, index } = action.payload;
			if (completed.indexOf(path) === -1) {
				completed = [...completed, path];
			}
			return {...state, completed};
		}
		case RESET_STEP: {
			const newState: any = { ...state };
			const stepIndex = action.payload.index;

			completed = completed.filter((path, index) => {
				return index < stepIndex;
			});

			// delete state after step index
			state.steps.map((o: any) => o.id).forEach((key, index) => {
				if (index > stepIndex) {
					delete newState[key];
				}
			});

			return { ...newState, completed };
		}
		case SAVE_FIELD_DATA:
			const { key, data } = action.payload;
			return {...state, data: {...state.data, [key]: data}};
		default:
			return state;
	}
};









// const reduceReducers = (...reducers: any[]) => {
// 	return (previous: any, current: any) => {
// 		reducers.reduce(
// 			(p, r) => r(p, current),
// 			previous
// 		);
// 	};
// };

// const handleAction = (type: any, reducer: any, defaultState: any) => {
// 	const types = type.toString().split('||');
// 	const [nextReducer, throwReducer] = ({}.toString.call(reducer) !== '[object Function]') ? (
// 		[reducer, reducer]
// 	) : (
// 		[reducer.next, reducer.throw].map(aReducer => (undefined !== aReducer ? reducer : aReducer))
// 	);
	
// 	return (state = defaultState, action: any) => {
// 		const { type: actionType } = action;
// 		if (!actionType || types.indexOf(actionType.toString() !== -1)) {
// 			return state;
// 		}

// 		return (action.error === true ? throwReducer : nextReducer)(state, action);
// 	};
// };

// const handleActions = (handlers: any, defaultState: any) => {
// 	const reducers = Object.keys(handlers).map((type) => {
// 		return handleAction(type, handlers[type], defaultState);
// 	});
// 	const reducer = reduceReducers(...reducers);
// 	return (state = defaultState, action: any) => reducer(state, action);
// };	

// const defaultState: StoreState = {
// 	stepIndex: 0,
// 	steps: []
// };

// const reducer = handleActions({
// 	[SET_STEP_INDEX]: (state = defaultState, action: Action<SET_STEP_INDEX>): StoreState => {
// 		return {...state, stepIndex: action.payload.index};
// 	},
// 	[SAVE_STEP_DATA]: (state = defaultState, action: Action<SAVE_STEP_DATA>): StoreState => {
// 		let steps = state.steps.map((state, index) => {
// 			if (action.payload.index == index) {
// 				return {...state, data: action.payload.data};
// 			} else {
// 				return state;
// 			}
// 		});
// 		return {...state, steps};
// 	}
// }, {});
// console.log(reducer);

// export default reducer;