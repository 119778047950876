import React from 'react';
import { ChromePicker } from 'react-color';
import tinycolor from 'tinycolor2';
import { get } from 'lodash';

export type FormRegistry = {
	fields: any,
	widgets: any,
	definitions: any,
	formContext: any
};

export type StateProps = {
	schema: any,
	uiSchema: any,
	idSchema: any,
	formData: any,
	errorSchema: any,
	registry: FormRegistry,
	formContext: any,
	name: string,
	onChange: (state: any) => void
};

export type State = {
    color: any;
    displayPicker: boolean;
}

export default class ColorPickerField extends React.Component<StateProps, State> {
    styles: any;

	constructor(props: StateProps) {
        super(props);
        
        const defaultColor = get(props, 'schema.default');
        const rgb = tinycolor(get(props, 'formData') || defaultColor);

		this.state = {
            ...props.formData,
            displayPicker: false,
            color: rgb ? rgb.toRgb() : null
        };
        
        this.styles = {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b}, ${this.state.color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0, 0, 0, .1)',
                display: 'inline-block',
                cursor: 'pointer',
                marginLeft: '10px'
            },
            popover: {
                position: 'absolute',
                zIndex: '1000',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            }
        };
    }

    componentDidUpdate(prevProps: StateProps, prevState: State) {
        if (this.props.formData !== prevProps.formData) {
            const color = tinycolor(this.props.formData);
            this.setState((prevState) => ({
                ...prevState,
                color: color.toRgb()
            }));
        }
    }

    handleClick = () => {
        this.setState({ displayPicker: !this.state.displayPicker });
    };
    
    handleClose = () => {
        this.setState({ displayPicker: false });
    };

	handleChange = (color: any, e: any) => {
		this.setState((prevState) => ({
            ...prevState,
            color: color.rgb
        }), () => this.props.onChange(tinycolor(color.rgb).toHex()));
    };
    
    handleInputChange = (e: any) => {
        const val = e.target.value;
        const color = (val.length > 0) ? tinycolor(val) : tinycolor();
        this.setState((prevState) => ({
            ...prevState,
            color: color.toRgb()
        }), () => this.props.onChange(val));
    };

	render() {
		const { schema, uiSchema, formData, registry: { fields } } = this.props;
		const title = uiSchema["ui:title"] ? uiSchema["ui:title"] : schema.title ? schema.title : this.props.name;
        const color = this.state.color || { r: 0, g: 0, b: 0, a: 1 };
        const styles = { ...this.styles };

        const swatchColor = `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;

		return (
			<div className="form-row">
				<div className="form-group">
                    <label>{title}</label>
					<div className="flex-row">
                        <input className="form-control" placeholder="" type="text" style={{ minWidth: '100px' }} value={this.props.formData || ''} onChange={this.handleInputChange} />
                        <div style={styles.swatch} onClick={this.handleClick}>
                            <div style={{ ...styles.color, background: swatchColor }} />
                        </div>
                        {this.state.displayPicker && (
                            <div style={styles.popover}>
                                <div style={styles.cover} onClick={this.handleClose} />
                                <ChromePicker color={this.state.color} onChange={this.handleChange} disableAlpha={true} />
                            </div>
                        )}
                    </div>
				</div>
			</div>
		);
	}
}