import React from 'react';
import { isFunction } from 'lodash';
import { ContentItem } from '../../types';
import { addClassName } from '../../../utils';

import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';

const copy = require('clipboard-copy');

export type Formatter = () => string;

export interface Props extends ContentItem {
	language: string;
	content: string | Formatter;
};

export default class CodeBlock extends React.Component<Props, {}> {
	_domNode: any;

	componentDidMount() {
		this._hightlight()
	}

	componentDidUpdate() {
		this._hightlight()
	}

	handleCopy = (e: any) => {
		let content: string|Formatter = this.props.content;
		if (isFunction(content)) {
			content = content();
		}
		copy(content);
	};

	private _hightlight() {
		if (this._domNode) {
			Prism.highlightElement(this._domNode, false)
		}
	}

	private _handleRefMount = (domNode: any) => {
		this._domNode = domNode
	};

	render() {
		const className: string = addClassName(this.props.className, 'content__codeblock code-prism');
		return (
			<div className={className}>
				<pre className={`language-${this.props.language}`}>
					<button className="btn-copy" onClick={this.handleCopy} title="Copy code to clipboard">
					<svg version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<path d="m79.168 16.668h-12.5v-4.168h-12.5c0-2.5-1.668-4.168-4.168-4.168s-4.168 1.668-4.168 4.168h-12.5v4.168h-12.5c-2.5 0-4.1641 1.6641-4.1641 4.1641v66.668c0 2.5 1.668 4.168 4.168 4.168h58.332c2.5 0 4.168-1.668 4.168-4.168l-0.003907-66.668c0-2.5-1.6641-4.1641-4.1641-4.1641zm-4.168 66.664h-50v-58.332h8.332v4.168h33.332l0.003907-4.168h8.332z"/>
						<path d="m33.332 41.668h33.332v8.332h-33.332z"/>
						<path d="m33.332 58.332h33.332v8.332h-33.332z"/>
					</svg>
					</button>
					<code ref={this._handleRefMount} className={`language-${this.props.language}`} data-language={this.props.language}>
					{
						(isFunction(this.props.content)) ? (
							(this.props.content as Formatter)()
						) : (
							this.props.content
						)
					}
					</code>
				</pre>
			</div>
		);	
	}
}