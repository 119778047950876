import React from 'react';
import uuid from 'uuid';
import Interweave from 'interweave';
import { isString, isObject, isArray, isFunction } from 'lodash';
import { ContentItem } from '../../types';
import { addClassName } from '../../../utils';

export interface Props extends ContentItem {
	id?: string,
	className?: string,
	renderer: any,
	selectedIndex: number,
	titles: string[] | Function,
	content: string|any
};

export type State = {
	selectedIndex: number
};

export default class Tabs extends React.Component<Props, State> {
	state: State = {
		selectedIndex: 0
	};

	setSelectedIndex(index: number) {
		this.setState(() => ({ selectedIndex: index }));
	}

	getContent(): string|any {
		let content: string = '';
		let output: any;

		// if item.content is a function, then we need to pass the store data to it for rendering
		if (({}.toString.call(this.props.content) === '[object Function]')) {
			output = this.props.content(this.state.selectedIndex, this.props.stateData);
		} else {
			output = this.props.content;
		}

		if (isObject(output) && !isArray(output)) {
			output = [output];
		}

		if (isArray(output)) {
			return output.map(item => {	
				return this.props.renderer(item);
			});
		} else if (isString(output)) {
			return output;
		}

		return content;
	}

	render() {
		const className: string = addClassName(this.props.className, 'content__tabs');
		const titles: string[] = (isFunction(this.props.titles)) ? this.props.titles(this.props.stateData) : this.props.titles;
		return (
			<div className={className}>
				<ul className="tabs">
					{
						titles.map((title, index) => {
							let className = `tab-${index}`;
							if (index == this.state.selectedIndex) className += ' selected';
							return <li key={`tab-${index}`} className={className} onClick={(e) => this.setSelectedIndex(index)}>{title}</li>
						})
					}
				</ul>
				<div className="tab-content">
					{this.getContent()}
				</div>
			</div>
		)
	}
}